import React, { lazy } from 'react'
import { AsyncBoundary, ErrorBoundary } from '@data-client/react'
import { Route } from 'wouter'

import { ErrorPage } from './components/ErrorPage'
import { Layout } from './components/Layout'

import { MainPage } from './pages/main'
import { ROUTES } from './constants'

import 'react-loading-skeleton/dist/skeleton.css'
import 'normalize.css'
import '@rainbow-me/rainbowkit/styles.css'
import './App.css'

const ConfigJson = lazy(() =>
  import('./pages/config').then((module) => ({ default: module.ConfigJson })),
)

export const App: React.FC = () => {
  return (
    <ErrorBoundary fallbackComponent={() => <ErrorPage />}>
      <AsyncBoundary errorComponent={() => <ErrorPage />}>
        <Layout>
          <Route path={ROUTES.stake} component={MainPage} />
          <Route path={ROUTES.config} component={ConfigJson} />
        </Layout>
      </AsyncBoundary>
    </ErrorBoundary>
  )
}
