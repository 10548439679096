export const ROW_TEMPLATE = [
  [
    [
      'minmax(200px, 1fr)',
      'minmax(250px, 1fr)',
      'minmax(150px, 1fr)',
      'minmax(150px, 1fr)',
      'minmax(150px, 1fr)',
      'minmax(150px, 1fr)',
      '30px',
    ],
    [],
  ],
  [
    [
      'minmax(200px, 1fr)',
      'minmax(250px, 1fr)',
      'minmax(150px, 1fr)',
      'minmax(150px, 1fr)',
      'minmax(150px, 1fr)',
      'minmax(150px, 1fr)',
      '30px',
    ],
    [],
  ],
]
