import { Suspense, useContext, useMemo, useState } from 'react'
import { CapacityCommitmentsStakerFilters } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerFilters'

import {
  Information,
  PersonalInformation,
  TableBody,
  TableControlsPanel,
} from '../../components'
import { CapacityCommitmentRow } from '../../components/CapacityCommitment'
import { Collateral } from '../../components/CapacityCommitment/Collateral'
import { CommitmentInfo } from '../../components/CapacityCommitment/CommitmentInfo'
import { CUsStats } from '../../components/CapacityCommitment/CUsStats'
import { ProviderInfo } from '../../components/CapacityCommitment/ProviderInfo'
import { Rewards } from '../../components/CapacityCommitment/Rewards'
import { StakeInfoCard } from '../../components/CapacityCommitment/StakeInfoCard'
import {
  CapacityCommitmentContentBlock,
  ROW_TEMPLATE,
} from '../../components/CapacityCommitment/styled'
import { SignerContext } from '../../components/SignerProvider'
import { Space } from '../../components/Space'
import { StakingModals } from '../../components/Stake'
import { Switch } from '../../components/Switch'
import { WithdrawCollateralModals } from '../../components/Withdraw/WithdrawCollateralModals'
import { WithdrawRewardModals } from '../../components/Withdraw/WithdrawRewardModals'
import WithdrawCollateralModalProvider from '../../components/WithdrawCollateralModalProvider'
import WithdrawModalProvider from '../../components/WithdrawModalProvider'
import { useFilters } from '../../hooks/useFilters'

import {
  CapacityCommitment_OrderBy,
  CapacityCommitmentStatus,
  OrderDirection,
} from '../../generated/graphql'
import { CapacityCommitmentTable as CapacityCommitmentTableStaking } from '../capacities/CapacitiesTable'
import {
  CapacityCommitmentSort,
  CapacityCommitmentTable as CapacityCommitmentTableDashboard,
} from '../dashboard/CapacitiesTable'

const ROW_TEMPLATE_DASHBOARD = [
  [
    [
      'minmax(200px, 1fr)',
      'minmax(250px, 1fr)',
      'minmax(120px, 1fr)',
      'minmax(150px, 1fr)',
      'minmax(80px, 1fr)',
      '30px',
    ],
    ['40px'],
  ],
  [['150px', '150px'], ['40px']],
]

export function MainPage() {
  const [onlyOfMe, setOnlyOfMe] = useState(false)
  const [stackFilters, setStakeFilter] =
    useFilters<CapacityCommitmentsStakerFilters>()
  const { signer } = useContext(SignerContext)

  const [filters, setFilter] = useFilters({
    statuses: [
      CapacityCommitmentStatus.WaitStart,
      CapacityCommitmentStatus.Active,
      CapacityCommitmentStatus.Inactive,
      CapacityCommitmentStatus.Failed,
      CapacityCommitmentStatus.Removed,
    ],
  })

  const [order, setOrder] = useState<CapacityCommitmentSort>('startEpoch:desc')
  const [orderBy, orderType] = order.split(':') as [
    CapacityCommitment_OrderBy,
    OrderDirection,
  ]

  const stakerId = signer?.address.toLowerCase().toLowerCase()
  const withSearch = useMemo(
    () => ({
      ...filters,
      searchBy: stackFilters.searchBy,
    }),
    [filters, stackFilters.searchBy],
  )

  return (
    <WithdrawCollateralModalProvider>
      <WithdrawModalProvider>
        {stakerId ? (
          <PersonalInformation stakerId={stakerId} />
        ) : (
          <Information />
        )}
        <Space height="38px" />
        <TableControlsPanel
          filters={stackFilters}
          setFilter={setStakeFilter}
          title="Available for Staking"
          searchPlaceholder="Search by Commitment ID / Provider ID / Provider name"
        />
        {signer?.address && (
          <>
            <Space height="16px" />
            <Switch
              label="Only for me"
              hint="Shows Capacity Commitments where Providers have set your wallet address as the sole possible staker"
              value={onlyOfMe}
              onSwitch={setOnlyOfMe}
            />
          </>
        )}
        <Space height="24px" />
        <Suspense fallback={<TableBody isLoading />}>
          <CapacityCommitmentTableStaking
            filters={stackFilters}
            columns={ROW_TEMPLATE}
            stakerId={onlyOfMe && stakerId ? stakerId : undefined}
            renderRow={({ capacityCommitment, onExpand, columns }) => (
              <CapacityCommitmentRow
                key={capacityCommitment.id}
                capacityCommitment={capacityCommitment}
                toggle={onExpand}
                columns={columns}
                staking
              >
                <CapacityCommitmentContentBlock>
                  <CommitmentInfo capacityCommitment={capacityCommitment} />
                  <ProviderInfo capacityCommitment={capacityCommitment} />
                  <StakeInfoCard capacityCommitment={capacityCommitment} />
                </CapacityCommitmentContentBlock>
              </CapacityCommitmentRow>
            )}
          />
        </Suspense>
        <Space height="24px" />
        <TableControlsPanel
          filters={{}}
          setFilter={() => {}}
          title={
            stakerId !== undefined
              ? `Your Capacity Commitments`
              : `Staked Capacity Commitments`
          }
          showSearch={false}
        />
        <CapacityCommitmentTableDashboard
          columns={ROW_TEMPLATE_DASHBOARD}
          orderBy={orderBy}
          orderType={orderType}
          filters={withSearch}
          setFilter={setFilter}
          setOrder={setOrder}
          stakerId={stakerId}
          renderRow={({ capacityCommitment, onExpand, columns }) => (
            <CapacityCommitmentRow
              key={capacityCommitment.id}
              capacityCommitment={capacityCommitment}
              toggle={onExpand}
              columns={columns}
            >
              <CapacityCommitmentContentBlock>
                <CUsStats capacityCommitment={capacityCommitment} />
                <Collateral capacityCommitment={capacityCommitment} />
                <Rewards capacityCommitment={capacityCommitment} />
              </CapacityCommitmentContentBlock>
            </CapacityCommitmentRow>
          )}
        />
        <WithdrawCollateralModals />
        <WithdrawRewardModals />
        <StakingModals />
      </WithdrawModalProvider>
    </WithdrawCollateralModalProvider>
  )
}
