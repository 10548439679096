import Skeleton from 'react-loading-skeleton'

import { Text, TextWithBadge } from './Text'
import { TokenBadge } from './TokenBadge'

const sizes = {
  small: 12,
  medium: 18,
  big: 20,
}

export default function TokenBalance({
  balance,
  size = 'medium',
  isLoading,
}: {
  size?: 'small' | 'medium' | 'big'
  balance?: Big
  isLoading?: boolean
}) {
  return (
    <TextWithBadge>
      {isLoading ? (
        <Skeleton width="60px" height="20px" />
      ) : (
        <>
          <Text size={sizes[size]}>
            {balance === undefined ? '-' : balance.toFixed(1)}
          </Text>
          <TokenBadge bg="purpleGradient">
            <Text size={10} weight={800} color="white">
              FLT
            </Text>
          </TokenBadge>
        </>
      )}
    </TextWithBadge>
  )
}
