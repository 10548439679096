import { Suspense, useContext, useMemo } from 'react'
import { useCache, useDLE } from '@data-client/react'
import Big from 'big.js'

import { CapacityCommitmentFundedQuery } from '../../store/CapacityCommitmentFundedQuery'
import { CapacityCommitmentRewards } from '../../store/CapacityCommitmentRewards'
import CCsReward from '../../store/CCsReward'
import { getCapacityCommitmentsRewards } from '../../store/getCapacityCommitmentRewards'
import { SignerContext } from '../SignerProvider'
import WithdrawRewardButton from '../Withdraw/WithdrawRewardButton'

import { CCRewardStatsBase } from './CCRewardStats'

export function CCStakerRewardsStatsContainer({
  stakerId,
}: {
  stakerId: string
}) {
  const { capacityCommitments } = useCache(CapacityCommitmentFundedQuery, {
    filters: {
      and: [
        {
          delegator: stakerId,
        },
      ],
    },
  })

  const {
    data: capacityCommitmentRewards,
    loading,
    error,
  } = useDLE(
    getCapacityCommitmentsRewards,
    capacityCommitments?.map((cc) => ({
      id: cc.id,
      stakingReward: cc.stakingReward,
    })) || [],
  )

  const rewardsSummary = useMemo(
    () =>
      CCsReward.fromJS(
        capacityCommitmentRewards?.reduce(
          (acc, cc) => ({
            stakerTotalCapacityRewards: acc.stakerTotalCapacityRewards.plus(
              cc.inVesting,
            ),
            stakerUnlockedCapacityRewards:
              acc.stakerUnlockedCapacityRewards.plus(cc.availableToClaim),
            stakerWithdrawnCapacityRewards:
              acc.stakerWithdrawnCapacityRewards.plus(cc.claimed),
          }),
          {
            stakerTotalCapacityRewards: Big(0),
            stakerUnlockedCapacityRewards: Big(0),
            stakerWithdrawnCapacityRewards: Big(0),
          },
        ),
      ),
    [capacityCommitmentRewards],
  )

  return (
    <CCStakerRewardsStatsBase
      isLoading={loading || !!error}
      rewardsSummary={rewardsSummary}
      capacityCommitments={capacityCommitmentRewards}
    />
  )
}

export function CCStakerRewardsStatsBase({
  isLoading,
  capacityCommitments,
  rewardsSummary,
}: {
  isLoading?: boolean
  capacityCommitments?: CapacityCommitmentRewards[]
  rewardsSummary: CCsReward
}) {
  const { signer } = useContext(SignerContext)

  return (
    <CCRewardStatsBase
      isLoading={isLoading}
      rewardsSummary={rewardsSummary}
      action={
        signer &&
        capacityCommitments &&
        capacityCommitments?.length > 0 &&
        rewardsSummary &&
        rewardsSummary?.availableToClaim.gt(0) && (
          <WithdrawRewardButton
            capacityCommitments={capacityCommitments}
            title="You are about to withdraw all rewards available to claim from all
          your capacity commitments"
          >
            Withdraw available rewards
          </WithdrawRewardButton>
        )
      }
    />
  )
}

export function CCStakerRewardsStats({ stakerId }: { stakerId: string }) {
  return (
    <Suspense fallback={<CCRewardStatsBase isLoading />}>
      <CCStakerRewardsStatsContainer stakerId={stakerId} />
    </Suspense>
  )
}
