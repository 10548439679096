import React from 'react'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import * as Dialog from '@radix-ui/react-dialog'

interface BaseModalProps {
  isVisible: boolean
  children: React.ReactNode | React.ReactNode[]
  maxWidth?: number
  onClose?: () => void
}

export const BaseModal: React.FC<BaseModalProps> = ({
  isVisible,
  maxWidth = 480,
  children,
  onClose,
}) => {
  return (
    <Dialog.Root open={isVisible}>
      <Dialog.Portal>
        <DialogOverlay onClick={onClose} />
        <ModalContainer maxWidth={maxWidth}>{children}</ModalContainer>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

const overlayShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const contentShow = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`

const contentShowMobile = keyframes`
  from {
    opacity: 0;
    transform: translate(0, 0) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
`

const DialogOverlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  inset: 0;
  animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
`

const ModalContainer = styled(Dialog.Content)<{ maxWidth: number }>`
  background-color: white;
  border-radius: 12px;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  max-height: 85vh;
  animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 9999;

  @media (max-width: 768px) {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transform: translate(0, 0);
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;
    height: 100%;
    height: 100dvh;
    border-radius: 0;
    animation: ${contentShowMobile} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
`
