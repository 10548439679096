import styled from '@emotion/styled'

import { CCCountStats } from './CCCountStats'
import { CCRewardStats } from './CCRewardStats'
import { CCStakerRewardsStats } from './CCStakerRewardsStats'
import { CCStakerCountStats } from './CCStakerStats'

export function Information() {
  return (
    <Rows>
      <CCCountStats />
      <CCRewardStats />
    </Rows>
  )
}

export function PersonalInformation({ stakerId }: { stakerId: string }) {
  return (
    <Rows>
      <CCStakerCountStats stakerId={stakerId} />
      <CCStakerRewardsStats stakerId={stakerId} />
    </Rows>
  )
}

const Rows = styled.div`
  display: grid;
  width: 100%;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 15px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
  }
`
