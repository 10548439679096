import { Suspense, useMemo } from 'react'
import { useSuspense } from '@data-client/react'

import {
  CommitmentLifecycleStatus,
  STATUS_NAMES,
} from '../../store/CapacityCommitmentGQL'
import CCCount, { CCCountQuery } from '../../store/CCStats'

import { InformationBlock } from './InformationBlock'

function CCCountStatsContainer() {
  const { cccount: statusSummary } = useSuspense(CCCountQuery, {})

  return <CCCountStatsBase statusSummary={statusSummary} />
}

export const HINTS: { [status in CommitmentLifecycleStatus]: string } = {
  [CommitmentLifecycleStatus.Unknown]: '',
  [CommitmentLifecycleStatus.Withdrawn]: '',
  [CommitmentLifecycleStatus.WaitDelegation]: '',
  [CommitmentLifecycleStatus.WaitStart]:
    'CC is activated and awaits becoming active at the start of the next epoch',
  [CommitmentLifecycleStatus.Active]:
    'CC is operational, and its CUs are either submitting CC proofs or participating in Deals',
  [CommitmentLifecycleStatus.Inactive]: `CC has successfully finished, but the staker hasn't yet withdrawn the collateral and rewards`,
  [CommitmentLifecycleStatus.Failed]: `CC has failed, but the staker hasn't yet withdrawn the collateral and rewards`,
  [CommitmentLifecycleStatus.Removed]:
    'Completed CCs from which the staker has withdrawn both collateral and rewards',
}

export function CCCountStatsBase({
  statusSummary,
  isLoading,
}: {
  statusSummary?: CCCount
  isLoading?: boolean
}) {
  const capacityData = useMemo(() => {
    const statuses = [
      CommitmentLifecycleStatus.WaitStart,
      CommitmentLifecycleStatus.Active,
      CommitmentLifecycleStatus.Inactive,
      CommitmentLifecycleStatus.Failed,
      CommitmentLifecycleStatus.Removed,
    ]

    return statuses.map((status) => ({
      label: STATUS_NAMES[status],
      value: statusSummary?.statuses ? statusSummary?.statuses[status] : 0,
      hint: HINTS[status],
    }))
  }, [statusSummary])

  return (
    <InformationBlock
      isLoading={isLoading}
      title="Capacity commitments"
      data={capacityData}
    />
  )
}

export function CCCountStats() {
  return (
    <Suspense fallback={<CCCountStatsBase isLoading />}>
      <CCCountStatsContainer />
    </Suspense>
  )
}
