import Big from 'big.js'
import { useAccount, useBalance } from 'wagmi'

import { nativeTokenToBig } from '../utils/nativeTokenToBig'

import { Button, ButtonProps } from './Button'

export default function ContractButton({
  threshold,
  disabled,
  children,
  variant,
  ...props
}: ButtonProps & { threshold: Big }) {
  const { address } = useAccount()
  const balance = useBalance({ address })

  const canStake =
    balance.data && nativeTokenToBig(balance.data.value).gte(threshold)
  const disabledButton = disabled || balance.isLoading || !canStake

  return (
    <Button
      {...props}
      disabled={disabledButton}
      variant={canStake ? variant : 'grey'}
    >
      {canStake ? children : 'Insufficient funds'}
    </Button>
  )
}
