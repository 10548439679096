import { Suspense, useMemo } from 'react'
import { useSuspense } from '@data-client/react'

import CCsReward, { CCsRewardQuery } from '../../store/CCsReward'

import { InformationBlock, InformationBlockProps } from './InformationBlock'

export function CCRewardStatsContainer() {
  const { ccsReward: rewardsSummary } = useSuspense(CCsRewardQuery, {})

  return <CCRewardStatsBase rewardsSummary={rewardsSummary} />
}

export function CCRewardStatsBase({
  isLoading,
  rewardsSummary,
  ...rest
}: {
  isLoading?: boolean
  rewardsSummary?: CCsReward
} & Partial<InformationBlockProps>) {
  const rewardsData = useMemo(() => {
    return [
      {
        label: 'Unvested',
        value: rewardsSummary?.stakerTotalCapacityRewards,
        token: 'FLT',
        hint: 'Earned rewards that are locked and will be vested later',
      },
      {
        label: 'Available to claim',
        value: rewardsSummary?.stakerUnlockedCapacityRewards,
        token: 'FLT',
        hint: 'Rewards that can be withdrawn immediately',
      },
      {
        label: 'Claimed',
        value: rewardsSummary?.stakerWithdrawnCapacityRewards,
        token: 'FLT',
        hint: `Rewards already withdrawn.
Note: If the provider initiates withdrawal first, rewards will be automatically sent to the staker's wallet`,
      },
    ]
  }, [rewardsSummary])

  return (
    <InformationBlock
      isLoading={isLoading}
      title="Rewards"
      data={rewardsData}
      {...rest}
    />
  )
}

export function CCRewardStats() {
  return (
    <Suspense fallback={<CCRewardStatsBase isLoading />}>
      <CCRewardStatsContainer />
    </Suspense>
  )
}
