import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import * as Accordion from '@radix-ui/react-accordion'

import { CaretIcon } from '../../assets/icons'

import { colors } from '../../constants/colors'

export const ROW_TEMPLATE = [
  [
    [
      'minmax(200px, 1fr)',
      'minmax(250px, 1fr)',
      'minmax(150px, 1fr)',
      'minmax(155px, 1fr)',
      'minmax(150px, 1fr)',
      'minmax(100px, 1fr)',
      '30px',
    ],
    ['minmax(40px, 1fr)'],
  ],
  [
    [
      'minmax(200px, 1fr)',
      'minmax(250px, 1fr)',
      'minmax(150px, 1fr)',
      'minmax(155px, 1fr)',
      'minmax(150px, 1fr)',
      'minmax(100px, 1fr)',
      '30px',
    ],
    ['40px'],
  ],
]

export const CaretIconStyled = styled(CaretIcon)`
  transform: rotate(90deg);
  transition: transform 200ms;
  color: #18181b;

  outline-style: none;

  &:focus {
    color: ${colors['blue']};
  }
`

export const RowHeader = styled(Accordion.Header)`
  width: fit-content;
  min-width: 100%;
  cursor: pointer;
  padding: 8px 12px;

  &[data-state='open'] {
    ${CaretIconStyled} {
      transform: rotate(180deg);
    }
  }
`

export const CapacityCommitmentContentBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  padding: 12px;

  @media (max-width: 783px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
  }
`

export const CapacityCommitmentInformation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 16px;
  min-height: 181px;
  background-color: ${colors.white};
  border-radius: 8px;

  @media (max-width: 783px) {
    min-height: inherit;
  }
`

export const InformationRow = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 12px;

  &:first-of-type {
    margin-top: 16px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  max-width: 240px;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
`

export const TextWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`

export const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`

export const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`

export const AccordionContent = styled(Accordion.Content)`
  overflow: hidden;

  &[data-state='open'] {
    animation: ${slideDown} 300ms;
  }
  &[data-state='closed'] {
    animation: ${slideUp} 300ms;
  }
`

export const ActionButtonContainer = styled.div`
  display: flex;
  width: fit-content;
  margin-top: auto;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 8px;
`

export const RowHeaderBase = styled.div`
  width: 100%;
  padding: 8px 12px;
`

export const StyledAccordionItem = styled(Accordion.Item)`
  width: fit-content;
  min-width: 100%;
`
