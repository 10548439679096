import { Endpoint } from '@data-client/rest'

import { getCapacityCommitmentRewardsBatch } from '../utils/getCapacityCommitmentRewardsBatch'
import { renderJsonString } from '../utils/renderJsonString'

import { CapacityCommitmentRewards } from './CapacityCommitmentRewards'

export const getCapacityCommitmentRewards = new Endpoint(
  async ({ id, stakingReward }: { id: string; stakingReward: Big }) => {
    const [rewards] = await getCapacityCommitmentRewardsBatch([
      { id, stakingReward },
    ])

    return rewards
  },
  {
    key: (params: unknown) =>
      `getCapacityCommitmentRewards ${renderJsonString(params)}`,
    name: 'getCapacityCommitmentRewards',
    schema: CapacityCommitmentRewards,
  },
)

export const getCapacityCommitmentsRewards = new Endpoint(
  async (ccs: { id: string; stakingReward: Big }[]) => {
    return getCapacityCommitmentRewardsBatch(ccs)
  },
  {
    key: (params: unknown) =>
      `getCapacityCommitmentRewards ${renderJsonString(params)}`,
    name: 'getCapacityCommitmentRewards',
    schema: [CapacityCommitmentRewards],
  },
)
