import Big from 'big.js'

import { SECONDS_IN_ONE_YEAR } from '../constants/time'

export function calculateExpectedAPR({
  fltPrice,
  epochDuration,
  usdTargetRevenuePerEpoch,
  rewardRatePerCollateral,
}: {
  rewardRatePerCollateral: Big
  fltPrice: Big
  usdTargetRevenuePerEpoch: Big
  epochDuration: number
}) {
  if (epochDuration === 0 || fltPrice.eq(0)) {
    return Big(0)
  }

  const epochsPerYear = SECONDS_IN_ONE_YEAR / epochDuration

  return rewardRatePerCollateral
    .times(epochsPerYear)
    .times(usdTargetRevenuePerEpoch)
    .div(fltPrice)
}
