import { useContext } from 'react'

import { getShortAddress } from '../../utils/getShortAddress'

import { TransactionWaitingModal } from '../Modals/TransactionWaitingModal'
import { SignerContext } from '../SignerProvider'
import { WithdrawModalContext, WithdrawStatus } from '../WithdrawModalProvider'

import { WithdrawRewardMainModal } from './WithdrawRewardMainModal'
import WithdrawTransactionFailedModal from './WithdrawTransactionFailedModal'
import WithdrawTransactionSuccessModal from './WithdrawTransactionSuccessModal'

export const WithdrawRewardModals = () => {
  const { signer } = useContext(SignerContext)
  const { data, status, setCurrentId, setStatus } =
    useContext(WithdrawModalContext)

  if (
    !data?.capacityCommitments ||
    data.capacityCommitments.length === 0 ||
    !signer
  )
    return null

  function onClose() {
    setCurrentId('')
  }

  const { capacityCommitments, title } = data

  if (status === 'waiting') {
    return (
      <WithdrawRewardMainModal
        title={title}
        signer={signer}
        onClose={onClose}
        onChangeStatus={(status: WithdrawStatus) => setStatus(data.id, status)}
        capacityCommitments={capacityCommitments}
      />
    )
  }

  if (status === 'pending') {
    return (
      <TransactionWaitingModal
        onClose={onClose}
        isVisible
        description={
          capacityCommitments.length === 1
            ? `Capacity commitment ID: ${getShortAddress(capacityCommitments[0].id, 12)} capacity commitment`
            : `Withdraw rewards from ${capacityCommitments.length} capacity commitments`
        }
      />
    )
  }

  if (status === 'success') {
    return (
      <WithdrawTransactionSuccessModal
        title={
          capacityCommitments.length === 1
            ? `Yor successfully withdraw rewards for ${getShortAddress(capacityCommitments[0].id, 12)} capacity commitment`
            : `Withdraw rewards from ${capacityCommitments.length} capacity commitments`
        }
        onClose={onClose}
      />
    )
  }

  if (status === 'failed') {
    return <WithdrawTransactionFailedModal onClose={onClose} />
  }

  return null
}
