import React from 'react'
import styled from '@emotion/styled'

import { CloseIcon } from '../../assets/icons'

import { colors } from '../../constants/colors'
import { Menu, MenuBlock, StyledActiveLink, StyledLink } from '../Menu'

import { BaseModal } from './BaseModal'
import { Close } from './styled'

interface StakeModalProps {
  isVisible: boolean
  onClose?: () => void
}

export const MenuModal: React.FC<StakeModalProps> = ({
  isVisible,
  onClose,
}) => {
  return (
    <BaseModal isVisible={isVisible} maxWidth={440} onClose={onClose}>
      <ModalRoot>
        <ModalContent>
          <Close onClick={onClose}>
            <CloseIconStyled />
          </Close>
          <Menu onClick={onClose} />
        </ModalContent>
      </ModalRoot>
    </BaseModal>
  )
}

const CloseIconStyled = styled(CloseIcon)`
  color: ${colors.grey400};
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  height: 100%;

  ${MenuBlock} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    height: 100%;
  }

  ${StyledLink}, ${StyledActiveLink} {
    font-size: 32px;
  }

  ${Close} {
    z-index: 2000;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
  }
`

const ModalRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
`
