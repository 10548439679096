import { useContext } from 'react'
import { useDLE } from '@data-client/react'

import CapacityCommitmentGQL from '../../store/CapacityCommitmentGQL'
import { getCapacityCommitmentRewards } from '../../store/getCapacityCommitmentRewards'
import HintedText from '../HintedText'
import { SignerContext } from '../SignerProvider'
import { Text } from '../Text'
import TokenBalance from '../TokenBalance'
import WithdrawRewardButton from '../Withdraw/WithdrawRewardButton'

import { ActionButtonContainer, CapacityCommitmentInformation } from './styled'
import { InformationRow } from './styled'

export function Rewards({
  capacityCommitment,
}: {
  capacityCommitment: CapacityCommitmentGQL
}) {
  const {
    data: rewards,
    loading,
    error,
  } = useDLE(getCapacityCommitmentRewards, {
    id: capacityCommitment.id,
    stakingReward: capacityCommitment.stakingReward,
  })

  const showLoading = loading || !!error
  const { signer } = useContext(SignerContext)

  return (
    <CapacityCommitmentInformation>
      <Text size={16}>Rewards</Text>
      <InformationRow>
        <HintedText
          text="Unvested"
          hint="Earned rewards that are locked and will be vested later"
        />
        <TokenBalance isLoading={showLoading} balance={rewards?.inVesting} />
      </InformationRow>
      <InformationRow>
        <HintedText
          text="Available to claim"
          hint="Rewards that can be withdrawn immediately"
        />
        <TokenBalance
          isLoading={showLoading}
          balance={rewards?.availableToClaim}
        />
      </InformationRow>
      <InformationRow>
        <HintedText
          text="Total claimed"
          hint="Rewards already withdrawn.
Note: If the provider initiates withdrawal first, rewards will be automatically sent to the staker's wallet"
        />
        <TokenBalance isLoading={showLoading} balance={rewards?.claimed} />
      </InformationRow>
      {signer && rewards && (
        <ActionButtonContainer>
          <WithdrawRewardButton
            title="You are about to withdraw reward available to claim from 
              your capacity commitment"
            capacityCommitments={[rewards]}
          >
            Withdraw rewards
          </WithdrawRewardButton>
        </ActionButtonContainer>
      )}
    </CapacityCommitmentInformation>
  )
}
