import { GQLEntity } from '@data-client/graphql'
import Big from 'big.js'
import { print } from 'graphql'

import { fixedPrecisionValueToBig } from '../utils/fixedPrecisionValueToBig'

import { ConstantsDocument } from '../generated/graphql'

import { gql } from './qql'

export class GraphNetworks extends GQLEntity {
  readonly fltPrice!: Big
  readonly usdTargetRevenuePerEpoch!: Big
  readonly coreEpochDuration!: number
  readonly initTimestamp!: number
  readonly capacityMaxFailedRatio!: number
  readonly corePrecision!: number

  readonly minRequiredProofsPerEpoch?: number
  readonly slashingRate!: number

  calculateTimestamp(epoch: number) {
    return (epoch - 1) * this.coreEpochDuration + this.initTimestamp
  }

  static schema = {
    usdTargetRevenuePerEpoch: fixedPrecisionValueToBig,
    fltPrice: fixedPrecisionValueToBig,
    coreEpochDuration: Number,
    initTimestamp: Number,
    capacityMaxFailedRatio: Number,
  }
}

export const ConstantsQuery = gql.query(print(ConstantsDocument), {
  graphNetworks: [GraphNetworks],
})
