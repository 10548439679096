import {
  BLOCK_SCOUT_URLS,
  CHAIN_IDS,
  ContractsENV,
  DEPLOYMENTS,
  RPC_URLS,
  SUBGRAPH_URLS,
} from '@fluencelabs/deal-ts-clients'
import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import { Chain } from 'viem'

// The network name for fluence contract clients: {kras, dar, stage, local}
export const FLUENCE_CLIENT_NETWORK: ContractsENV =
  import.meta.env.VITE_FLUENCE_CLIENT_NETWORK ?? 'stage'
export const BLOCKSCOUT_URL =
  (import.meta.env.VITE_BLOCKSCOUT_URL ?? FLUENCE_CLIENT_NETWORK === 'local')
    ? null
    : BLOCK_SCOUT_URLS[FLUENCE_CLIENT_NETWORK]
export const RPC_URL =
  import.meta.env.VITE_RPC_URL ?? RPC_URLS[FLUENCE_CLIENT_NETWORK]
export const SUBGRAPH_URL =
  import.meta.env.VITE_SUBGRAPH_URL ?? SUBGRAPH_URLS[FLUENCE_CLIENT_NETWORK]
export const BRIDGE_URL =
  import.meta.env.VITE_BRIDGE_URL ?? 'https://bridge.stage.fluence.dev'
export const VITE_EXPLORER_URL =
  import.meta.env.VITE_EXPLORER_URL ?? 'https://explorer.stage.fluence.dev'
export const CHAIN_ID = parseInt(
  import.meta.env.VITE_CHAIN_ID ?? `${CHAIN_IDS[FLUENCE_CLIENT_NETWORK]}`,
)
export const DEPLOYMENT = DEPLOYMENTS[FLUENCE_CLIENT_NETWORK]
export const WAGMI_PROJECT_ID =
  import.meta.env.VITE_WAGMI_PROJECT_ID ?? '8e37443eb4012ab3b31a4fed70e1b2b7'
export const SENTRY_DNS = import.meta.env.VITE_SENTRY_SENTRY_DNS
export const NFT_ADDRESS =
  import.meta.env.VITE_NFT_ADDRESS ??
  '0x980bee75bcf7560fb5006b8f278887244188ae4a'
export const NFT_COLLECTION_URL =
  import.meta.env.VITE_NFT_COLLECTION_URL ?? 'https://nft.fluence.network'
export const FILTER_ONLY_APPROVED_PROVIDERS_DEFAULT =
  import.meta.env.VITE_FILTER_ONLY_APPROVED_PROVIDERS_DEFAULT != undefined
    ? import.meta.env.VITE_FILTER_ONLY_APPROVED_PROVIDERS_DEFAULT == 'true'
    : true
export const FORMAT_PAYMENT_TOKEN_TO_FIXED_DEFAULT = 3
export const FORMAT_NATIVE_TOKEN_TO_FIXED_DEFAULT = 6

export const PRECISION = 10000000

export const CHAIN = {
  id: CHAIN_ID,
  name: `Fluence ${FLUENCE_CLIENT_NETWORK}`,
  nativeCurrency: { name: 'FLT', symbol: 'FLT', decimals: 18 },
  rpcUrls: {
    default: { http: [RPC_URL] },
  },
  ...(BLOCKSCOUT_URL === null
    ? {}
    : {
        blockExplorers: {
          default: {
            name: 'BlockScout',
            url: BLOCKSCOUT_URL,
          },
        },
      }),
} as const satisfies Chain

export const WAGMI_CONFIG = getDefaultConfig({
  appName: 'Fluence Staking',
  appUrl: window.location.origin,
  appIcon: `${window.location.origin}/apple-touch-icon.png`,
  projectId: WAGMI_PROJECT_ID,
  chains: [CHAIN],
})
