import * as Sentry from '@sentry/react'

import { SENTRY_DNS } from './constants/config'

Sentry.init({
  dsn: SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  tracesSampleRate: 0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
})
