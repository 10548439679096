import { ReactNode } from 'react'

import { Button } from './Button'

export default function NetworkBadge({
  name,
  openChainModal,
  icon,
}: {
  name: string
  openChainModal: (e: React.MouseEvent) => void
  icon?: ReactNode
}) {
  return (
    <Button variant="outlineShadow" onClick={openChainModal} leftIcon={icon}>
      {name}
    </Button>
  )
}
