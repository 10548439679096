import React, { useMemo } from 'react'
import { AsyncBoundary, useLive, useQuery } from '@data-client/react'
import styled from '@emotion/styled'
import { formatDistanceStrict } from 'date-fns'

import { CloseIcon } from '../../assets/icons'
import { useExpectedAPR } from '../../hooks/useExpectedAPR'
import { formatPercent } from '../../utils/formatPercent'
import { formatUnixTimestamp } from '../../utils/formatUnixTimestamp'
import { getShortAddress } from '../../utils/getShortAddress'

import { colors } from '../../constants/colors'
import CapacityCommitmentGQL from '../../store/CapacityCommitmentGQL'
import { getConstants } from '../../store/Constants'
import { Button } from '../Button'
import ContractButton from '../ContractButton'
import HintedText from '../HintedText'
import { Space } from '../Space'
import { Text } from '../Text'
import TokenBalance from '../TokenBalance'

import { BaseModal } from './BaseModal'
import { Close } from './styled'
import { Warning } from './Warning'

interface StakeModalProps {
  isVisible: boolean
  id: string
  onClose?: () => void
  onStake?: () => void
}

const StakeModalContent: React.FC<StakeModalProps> = ({
  onStake,
  onClose,
  id,
}) => {
  const capacity = useQuery(CapacityCommitmentGQL, { id })

  const expectedAPR = useExpectedAPR(capacity)
  const constants = useLive(getConstants)

  const period = useMemo(() => {
    if (!constants || !capacity) return null

    const epoch = constants.currentEpoch
    const epochDuration = constants.epochDuration
    const start = constants.calculateTimestamp(epoch + 1)

    return {
      start,
      end: start + capacity.duration * epochDuration,
    }
  }, [constants, capacity])

  const handleClose = () => {
    onClose?.()
  }

  const handleStake = () => {
    onStake?.()
  }

  if (!constants || !capacity) return null

  return (
    <>
      <ParametersContainer>
        {period && (
          <>
            <InfoRow>
              <InfoRow>
                <HintedText
                  text="Staking duration"
                  hint="How long the staked funds will be locked"
                />
              </InfoRow>
              <Text size={12}>
                {formatDistanceStrict(period.start * 1000, period.end * 1000)}
              </Text>
            </InfoRow>

            <InfoRow>
              <InfoRow>
                <HintedText text="Capacity commitment start" />
              </InfoRow>
              <Text size={12}>{formatUnixTimestamp(period.start).date}</Text>
            </InfoRow>
            <InfoRow>
              <InfoRow>
                <HintedText text="Expected unlock date" />
              </InfoRow>
              <Text size={12}>{formatUnixTimestamp(period.end).date}</Text>
            </InfoRow>
          </>
        )}
        <InfoRow>
          <InfoRow>
            <HintedText
              text="Expected apr"
              hint="Estimated annual percentage return based on target reward per CU per epoch"
            />
          </InfoRow>
          <Text size={12}>
            {expectedAPR === null ? '—' : formatPercent(expectedAPR)}
          </Text>
        </InfoRow>
      </ParametersContainer>
      <Warning>
        Immediately after you stake FLT tokens, you will not be able to withdraw
        them from the Capacity commitment until it ends.
      </Warning>
      <ButtonsContainer>
        <Button variant="grey" isLarge onClick={handleClose}>
          Cancel
        </Button>
        <ContractButton
          threshold={capacity.requiredCollateral}
          variant="black"
          isLarge
          onClick={handleStake}
        >
          Stake
        </ContractButton>
      </ButtonsContainer>
    </>
  )
}

export const StakeModal: React.FC<StakeModalProps> = ({
  isVisible,
  onStake,
  onClose,
  id,
}) => {
  const capacity = useQuery(CapacityCommitmentGQL, { id })
  const capacityCommitmentId = getShortAddress(id || '', 12)

  if (!capacity) return null

  return (
    <BaseModal isVisible={isVisible} maxWidth={440} onClose={onClose}>
      <ModalRoot>
        <ModalHeader>
          <InfoTextContainer>
            <Text size={20} color="grey500">
              You are about to stake
              <BadgeContainer>
                <TokenBalance
                  size="big"
                  balance={capacity?.requiredCollateral}
                />
              </BadgeContainer>
              in capacity commitment&nbsp;
            </Text>
            <Text size={14} color="grey400">
              ID: {capacityCommitmentId}
            </Text>
          </InfoTextContainer>
          <Close>
            <CloseIconStyled onClick={onClose} />
          </Close>
        </ModalHeader>
        <Divider />
        <ModalContent>
          <AsyncBoundary
            fallback={
              <ButtonsContainer>
                <Button variant="dash" isLarge disabled>
                  Loading...
                </Button>
              </ButtonsContainer>
            }
            errorComponent={() => (
              <Warning>
                <Text as="p">
                  Oops! Looks like there’s a problem while loading information.
                </Text>
                <Space height="0.5rem" />
                <Text as="p">
                  No need to worry—your funds are completely safe and secure on
                  the Fluence blockchain. Please try again in a little bit!
                </Text>
              </Warning>
            )}
          >
            <StakeModalContent
              onClose={onClose}
              onStake={onStake}
              isVisible={isVisible}
              id={id}
            />
          </AsyncBoundary>
        </ModalContent>
      </ModalRoot>
    </BaseModal>
  )
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 32px;
  flex-grow: 1;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 32px;
`

const ModalRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10;

  @media (max-width: 500px) {
    min-height: 100vh;
  }
`

const CloseIconStyled = styled(CloseIcon)`
  color: ${colors.grey400};
  cursor: pointer;
`

const Divider = styled.div`
  border: 1px solid ${colors.grey100};
  width: 100%;
`

const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > ${Text} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
`

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  width: min-content;
  gap: 8px;
  margin: 0 10px;
`

const ParametersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: space-between;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  & > button:last-of-type {
    width: 100%;
  }

  @media (max-width: 768px) {
    margin-top: auto;
    flex-direction: column;
    align-self: flex-end;
  }
`
