import { Endpoint, schema } from '@data-client/rest'
import { Contracts, IMulticall__factory } from '@fluencelabs/deal-ts-clients'
import Big from 'big.js'
import { ContractTransaction, JsonRpcSigner } from 'ethers'

import chunk from '../utils/chunk'
import { getCapacityCommitmentRewardsBatch } from '../utils/getCapacityCommitmentRewardsBatch'
import { renderJsonString } from '../utils/renderJsonString'

import { DEPLOYMENT } from '../constants/config'

import { CapacityCommitmentRewards } from './CapacityCommitmentRewards'
import CCsReward from './CCsReward'

async function setupMultiCall({
  signer,
  address,
}: {
  address: string
  signer: JsonRpcSigner
}) {
  const { multicall } = IMulticall__factory.connect(address, signer)

  return multicall
}

const BATCH_SIZE = 10

export const withdrawRewardsCapacityCommitment = new Endpoint(
  async ({
    capacityCommitments,
    signer,
  }: {
    capacityCommitments: { id: string; stakingReward: Big }[]
    signer: JsonRpcSigner
  }) => {
    const contracts = new Contracts(signer, DEPLOYMENT)

    const populatedTxs = await Promise.all(
      capacityCommitments.map(({ id }) =>
        contracts.diamond.withdrawReward.populateTransaction(id, {
          maxPriorityFeePerGas: 0,
        }),
      ),
    )

    const multiCall = await setupMultiCall({
      signer,
      address: populatedTxs[0].to,
    })

    const receipts = []

    for (const txs of chunk<ContractTransaction>(populatedTxs, BATCH_SIZE)) {
      const populatedTsxData = txs.map((tx) => tx.data)

      const tx = await multiCall(populatedTsxData)
      const response = await tx.wait()

      receipts.push(response)
    }

    const rewards = await getCapacityCommitmentRewardsBatch(capacityCommitments)

    return {
      rewards,
      stats: {
        id: 'total',
      },
    }
  },
  {
    key: (params: unknown) =>
      `withdrawRewardsCapacityCommitment ${renderJsonString(params)}`,
    name: 'withdrawRewardsCapacityCommitment',
    schema: {
      rewards: [CapacityCommitmentRewards],
      status: new schema.Invalidate(CCsReward),
    },
  },
)
