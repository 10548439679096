import { useDLE } from '@data-client/react'

import { getConstants } from '../store/Constants'

export function useCoolDown(failedEpoch?: number) {
  const { data: constants, loading } = useDLE(getConstants)

  if (failedEpoch === undefined || !constants)
    return {
      availableToWithdraw: 0,
      isAvailableToWithdraw: false,
      loading,
    }
  const { withdrawEpochsAfterFailed, epochDuration } = constants
  const coolDown = withdrawEpochsAfterFailed * epochDuration

  const period = coolDown * 1000

  const availableToWithdraw =
    (constants.calculateTimestamp(failedEpoch) + coolDown) * 1000

  const isAvailableToWithdraw = availableToWithdraw - Date.now() <= 0

  return {
    coolDownPeriod: period,
    availableToWithdraw,
    isAvailableToWithdraw,
    loading,
  }
}
