import Big from 'big.js'

import { calculateExpectedAPR } from '../utils/calculateExpectedAPR'

import { useConstants } from './useConstants'

export function useExpectedAPR(capacity?: { rewardRatePerCollateral: Big }) {
  const constants = useConstants()

  if (!constants || !capacity) return null

  const { coreEpochDuration, fltPrice, usdTargetRevenuePerEpoch } = constants
  const { rewardRatePerCollateral } = capacity

  return calculateExpectedAPR({
    fltPrice,
    rewardRatePerCollateral,
    usdTargetRevenuePerEpoch,
    epochDuration: coreEpochDuration,
  })
}
