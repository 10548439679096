import { print } from 'graphql'

import {
  CapacityCommitment_OrderBy,
  CapacityCommitmentsDocument,
  CapacityCommitmentsQueryVariables,
  CapacityCommitmentStatus,
  OrderDirection,
} from '../generated/graphql'

import CapacityCommitmentGQL, {
  CommitmentLifecycleStatus,
} from './CapacityCommitmentGQL'
import { GraphNetworks } from './GraphNetworks'
import { gql } from './qql'

type ProcessResult = {
  status?: string
  collateralWithdrawn?: boolean
}

export function processCapacityCommitment(input: Partial<ProcessResult>) {
  if (input.collateralWithdrawn) {
    input.status = CommitmentLifecycleStatus.Withdrawn
  }

  return input
}

export function setupVariables({
  searchBy,
  stakerId,
  orderBy,
  orderType,
}: {
  stakerId?: string
  searchBy?: string
  orderBy?: CapacityCommitment_OrderBy
  orderType?: OrderDirection
}): CapacityCommitmentsQueryVariables {
  const searchLowerCase = searchBy?.toLowerCase()

  return {
    limit: 1000,
    orderBy,
    orderType,
    filters: {
      and: [
        searchLowerCase && searchLowerCase?.length > 0
          ? {
              or: [
                { id: searchLowerCase },
                {
                  provider_: { name_contains_nocase: searchLowerCase },
                },
                { delegator_: { id: searchLowerCase } },
                { provider_: { id: searchLowerCase } },
                { peer_: { id: searchLowerCase } },
              ],
            }
          : {},
        {
          status: CapacityCommitmentStatus.WaitDelegation,
          deleted: false,
          delegator: stakerId,
        },
      ],
    },
  }
}

export const CapacityCommitmentGQLQuery = gql
  .query(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_variables: CapacityCommitmentsQueryVariables) =>
      print(CapacityCommitmentsDocument),
    {
      capacityCommitments: [CapacityCommitmentGQL],
      graphNetworks: [GraphNetworks],
    },
  )
  .extend({
    process({ capacityCommitments, ...rest }) {
      return {
        ...rest,
        capacityCommitments: capacityCommitments.map(processCapacityCommitment),
      }
    },
  })
