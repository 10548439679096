import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import { AsyncBoundary, useCache, useSuspense } from '@data-client/react'
import { formatDistanceToNowStrict } from 'date-fns'

import { useCoolDown } from '../../hooks/useCoolDown'
import { useCurrentCollateral } from '../../hooks/useCurrentCollateral'
import { getShortAddress } from '../../utils/getShortAddress'

import { CapacityCommitmentFunded } from '../../store/CapacityCommitmentFunded'
import { getCapacityCommitmentBasic } from '../../store/getCapacityCommitmentBasic'
import { Button } from '../Button'
import { Copyable } from '../Copyable'
import { Warning } from '../Modals/Warning'
import {
  ButtonsContainer,
  ModalFooter,
  WithdrawModal,
  WithdrawModalFooterProps,
} from '../Modals/WithdrawModal'
import { Space } from '../Space'
import { Text } from '../Text'
import TokenBalance from '../TokenBalance'

import {
  RowWithdrawTotal,
  RowWithdrawTotalWrapper,
  WithdrawModalTitle,
} from './styled'

const Footer: FC<WithdrawModalFooterProps & { id: string }> = ({
  handleClose,
  handleSubmit,
  id,
}) => {
  const cc = useSuspense(getCapacityCommitmentBasic, {
    id,
  })

  const basicCapacityCommitment = useCoolDown(cc?.failedEpoch)
  const isAvailableToWithdraw = basicCapacityCommitment?.isAvailableToWithdraw

  return (
    <ModalFooter>
      {isAvailableToWithdraw ? (
        <ButtonsContainer>
          <Button variant="grey" isLarge onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="black" isLarge onClick={handleSubmit}>
            Withdraw
          </Button>
        </ButtonsContainer>
      ) : (
        <ButtonsContainer>
          <Button variant="dash" isLarge>
            Wait end of cooldown period
          </Button>
        </ButtonsContainer>
      )}
    </ModalFooter>
  )
}

export function WithdrawFromFailedCC({
  capacityCommitment,
  onWithdraw,
  onClose,
}: {
  onClose: () => void
  capacityCommitment: CapacityCommitmentFunded
  onWithdraw: () => void
}) {
  const cc = useCache(getCapacityCommitmentBasic, {
    id: capacityCommitment.id,
  })

  const coolDown = useCoolDown(cc?.failedEpoch)

  const isAvailableToWithdraw = coolDown?.isAvailableToWithdraw
  const shouldWait =
    !isAvailableToWithdraw &&
    coolDown?.coolDownPeriod &&
    coolDown?.coolDownPeriod > 0

  const currentCollateral = useCurrentCollateral(capacityCommitment)

  return (
    <WithdrawModal
      title={
        <WithdrawModalTitle>
          <Text size={20} color="grey500">
            You are about to withdraw collateral from capacity commitment
            with&nbsp;
            <Text size={20} color="red">
              Failed
            </Text>
            &nbsp;status
          </Text>
          <Copyable value={capacityCommitment.id}>
            <Text size={12} color="grey500">
              ID: {getShortAddress(capacityCommitment.id, 24)}
            </Text>
          </Copyable>
        </WithdrawModalTitle>
      }
      isVisible
      onClose={onClose}
      onSubmit={onWithdraw}
      warning={
        shouldWait &&
        coolDown?.coolDownPeriod && (
          <RowWithdrawTotalWrapper>
            <Warning>
              Because you are withdrawing collateral from a FAILED commitment,
              the withdrawal period is{' '}
              {formatDistanceToNowStrict(Date.now() + coolDown?.coolDownPeriod)}
              .
            </Warning>
          </RowWithdrawTotalWrapper>
        )
      }
      footer={(props) => (
        <AsyncBoundary
          fallback={
            <ModalFooter>
              <ButtonsContainer>
                <Button variant="dash" isLarge disabled>
                  Loading...
                </Button>
              </ButtonsContainer>
            </ModalFooter>
          }
          errorComponent={() => (
            <Warning>
              <Text as="p">
                Oops! Looks like there’s a problem while loading information.
              </Text>
              <Space height="0.5rem" />
              <Text as="p">
                No need to worry—your funds are completely safe and secure on
                the Fluence blockchain. Please try again in a little bit!
              </Text>
            </Warning>
          )}
        >
          <Footer id={capacityCommitment.id} {...props} />
        </AsyncBoundary>
      )}
    >
      <RowWithdrawTotalWrapper>
        <RowWithdrawTotal>
          <Text size={12} color="grey500" weight={500}>
            {currentCollateral
              ? 'Collateral to withdraw'
              : `Don't able to withdraw right now`}
          </Text>
          {currentCollateral && <TokenBalance balance={currentCollateral} />}
        </RowWithdrawTotal>
        {(coolDown.loading || shouldWait) && (
          <RowWithdrawTotal>
            <Text size={12} color="grey500" weight={500}>
              End cooldown
            </Text>
            {shouldWait && (
              <Text size={18}>
                ~ {formatDistanceToNowStrict(coolDown.coolDownPeriod)}
              </Text>
            )}
            {coolDown.loading && <Skeleton height="18px" width="80px" />}
          </RowWithdrawTotal>
        )}
      </RowWithdrawTotalWrapper>
    </WithdrawModal>
  )
}
