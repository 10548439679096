import styled from '@emotion/styled'

import { Colors, colors } from '../constants/colors'

export const TokenBadge = styled.div<{ bg?: Colors; rounded?: boolean }>`
  display: flex;
  align-items: center;
  background: ${({ bg = 'grey200' }) => colors[bg]};
  border-radius: ${({ rounded }) => (rounded ? '8px' : '4px')};
  padding: 4px 6px;
  height: 16px;
  width: fit-content;
`
