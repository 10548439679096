import styled from '@emotion/styled'

import { Search } from '../Search'
import { Text } from '../Text'

export function TableControlsPanel({
  filters,
  setFilter,
  title,
  searchPlaceholder,
  showSearch = true,
}: {
  title?: string
  filters: { searchBy?: string }
  setFilter: (name: 'searchBy', value: string) => void
  searchPlaceholder?: string
  showSearch?: boolean
}) {
  return (
    <Header>
      <Text size={22}>{title}</Text>
      {showSearch && (
        <FiltersBlock>
          <Search
            value={filters.searchBy ?? ''}
            onChange={(search) => setFilter('searchBy', search)}
            placeholder={searchPlaceholder ?? 'Search'}
          />
        </FiltersBlock>
      )}
    </Header>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;

  @media (max-width: 900px) {
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    gap: 16px;
  }
`

const FiltersBlock = styled.div`
  display: flex;
  width: 420px;
  max-width: 100%;
`
