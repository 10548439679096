import { GQLEntity } from '@data-client/graphql'
import { print } from 'graphql'

import { CapacityCommitmentStatus, CcCountDocument } from '../generated/graphql'

import { CommitmentLifecycleStatus } from './CapacityCommitmentGQL'
import { gql } from './qql'

export default class CCCount extends GQLEntity {
  readonly statuses!: {
    [status in CommitmentLifecycleStatus]: number
  }
}

export const CCCountQuery = gql
  .query(print(CcCountDocument), {
    cccount: CCCount,
  })
  .extend({
    process({
      cccount,
    }: {
      cccount: {
        ccInactiveCount: number
        ccActiveCount: number
        ccWaitDelegationCount: number
        ccWaitStartCount: number
        ccFailedCount: number
        ccRemovedCount: number
      }
    }) {
      const {
        ccInactiveCount,
        ccActiveCount,
        ccWaitDelegationCount,
        ccWaitStartCount,
        ccFailedCount,
        ccRemovedCount,
        ...rest
      } = cccount

      return {
        cccount: {
          ...rest,
          statuses: {
            [CapacityCommitmentStatus.Inactive]: ccInactiveCount,
            [CapacityCommitmentStatus.Active]: ccActiveCount,
            [CapacityCommitmentStatus.WaitDelegation]: ccWaitDelegationCount,
            [CapacityCommitmentStatus.WaitStart]: ccWaitStartCount,
            [CapacityCommitmentStatus.Failed]: ccFailedCount,
            [CapacityCommitmentStatus.Removed]: ccRemovedCount,
          },
        },
      }
    },
  })
