import { createContext, ReactNode, useCallback, useMemo, useState } from 'react'

import { CapacityCommitmentFunded } from '../store/CapacityCommitmentFunded'

import { WithdrawStatus } from './WithdrawModalProvider'

interface WithdrawCollateralModalData {
  id: string
  title: string
  status: WithdrawStatus
  transactionHash?: string
  capacityCommitment: CapacityCommitmentFunded
}

export const WithdrawCollateralModalContext = createContext<{
  data: WithdrawCollateralModalData | null
  setData: (data: WithdrawCollateralModalData) => void
  status?: WithdrawStatus
  setStatus: (id: string, status: WithdrawStatus) => void
  setCurrentId: (id: string) => void
}>({
  data: null,
  setData: () => {},
  setStatus: () => {},
  setCurrentId: () => {},
})

export default function WithdrawCollateralModalProvider({
  children,
}: {
  children: ReactNode
}) {
  const [store, setStore] = useState<{
    [key: string]: WithdrawCollateralModalData | null
  }>({})
  const [currentId, setCurrentId] = useState('')

  const update = useCallback(
    (id: string, data: WithdrawCollateralModalData | null) => {
      if (id) setCurrentId(id)
      setStore({ ...store, [id]: data })
    },
    [setCurrentId, setStore, store],
  )

  const current = store[currentId] || null

  const value = useMemo(
    () => ({
      data: current,
      status: current?.status,
      setData: (data: WithdrawCollateralModalData) => update(data.id, data),
      setStatus: (id: string, status: WithdrawStatus) => {
        const record = store[id]
        if (record && id) update(id, { ...record, status })
      },
      setCurrentId,
    }),
    [store, current, update, setCurrentId],
  )

  return (
    <WithdrawCollateralModalContext.Provider value={value}>
      {children}
    </WithdrawCollateralModalContext.Provider>
  )
}
