import React, { forwardRef } from 'react'
import styled from '@emotion/styled'

import { colors } from '../constants/colors'

import { UppercaseText } from './Text'

export interface ButtonProps {
  leftIcon?: React.ReactNode
  children: React.ReactNode | React.ReactNode[]
  rightIcon?: React.ReactNode
  variant: keyof typeof BUTTON_MAP
  isLarge?: boolean
  onClick?: (e: React.MouseEvent) => void
  className?: string
  disabled?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant,
      leftIcon,
      rightIcon,
      className,
      isLarge,
      onClick,
      disabled,
    },
    ref,
  ) => {
    const Button = BUTTON_MAP[variant]

    return (
      <Button
        ref={ref}
        disabled={disabled}
        className={className}
        onClick={onClick}
        isLarge={isLarge}
      >
        {leftIcon}
        <StyledText color="white" size={10} weight={800}>
          {children}
        </StyledText>
        {rightIcon}
      </Button>
    )
  },
)

Button.displayName = 'Button'

const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  gap: 4px;
`

const StyledText = styled(UppercaseText)`
  display: flex;
  align-items: center;
`

const AuthButton = styled(BaseButton)`
  background-color: ${colors.black900};
  padding: 6px 16px;

  border-radius: 16px;
  height: 28px;
`

const OutlineButton = styled(BaseButton)`
  padding: 7px 8px;
  border-radius: 8px;
  border: 1px solid ${colors.grey200};
  height: 32px;

  ${StyledText} {
    color: ${colors.black900};
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
  }
`

const BlackButton = styled(BaseButton)<{
  isLarge?: boolean
}>`
  padding: 0 16px;
  border-radius: 8px;
  height: ${({ isLarge }) => (isLarge ? 48 : 32)}px;
  background-color: ${colors.black900};

  ${StyledText} {
    color: ${colors.white};
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
  }
`

const SuccessButton = styled(BaseButton)<{
  isLarge?: boolean
}>`
  padding: 0 16px;
  border-radius: 8px;
  height: ${({ isLarge }) => (isLarge ? 48 : 32)}px;
  border: 1px solid ${colors.green};

  ${StyledText} {
    color: ${colors.green};
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
  }
`

const GreyButton = styled(BaseButton)<{
  isLarge?: boolean
}>`
  padding: 0 16px;
  border-radius: 8px;
  height: ${({ isLarge }) => (isLarge ? 48 : 32)}px;
  background-color: ${colors.grey100};

  ${StyledText} {
    color: ${colors.grey400};
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
  }
`

const OutlineShadowButton = styled(BaseButton)`
  padding: 12px;
  border-radius: 6px;
  height: 34px;

  border-radius: 6px;
  background: ${colors.white};
  box-shadow:
    0px 1px 3px 0px rgba(24, 24, 27, 0.1),
    0px 1px 2px 0px rgba(24, 24, 27, 0.06);

  ${StyledText} {
    color: ${colors.black900};
    text-transform: none;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
  }
`

const GreyDashButton = styled(BaseButton)<{
  isLarge?: boolean
}>`
  padding: 0 16px;
  border-radius: 8px;
  height: ${({ isLarge }) => (isLarge ? 48 : 32)}px;
  border-color: ${colors.grey100};
  border-style: dashed;

  ${StyledText} {
    color: ${colors.grey400};
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
  }
`

const BUTTON_MAP = {
  auth: AuthButton,
  outline: OutlineButton,
  outlineShadow: OutlineShadowButton,
  black: BlackButton,
  success: SuccessButton,
  grey: GreyButton,
  dash: GreyDashButton,
}
