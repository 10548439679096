import { CommitmentLifecycleStatus } from '../store/CapacityCommitmentGQL'

import { Colors } from './colors'

export const STATUS_COLORS: {
  [status in CommitmentLifecycleStatus]: Colors
} = {
  Unknown: 'red',
  WaitDelegation: 'grey300',
  WaitStart: 'orange',
  Active: 'green',
  Inactive: 'blue',
  Failed: 'red',
  Removed: 'grey300',
  Withdrawn: 'grey300',
}
