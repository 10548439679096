import styled from '@emotion/styled'
import { Box as BoxBase, BoxProps, Flex as FlexBase } from 'reflexbox'

export const Box = styled(BoxBase)<BoxProps & { gap?: string }>`
  ${({ gap }) => gap && `gap: ${gap};`}
`

export const Flex = styled(FlexBase)<BoxProps & { gap?: string }>`
  ${({ gap }) => gap && `gap: ${gap};`}
`
