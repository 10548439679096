import { formatDistanceToNowStrict } from 'date-fns'

import { getShortAddress } from '../../utils/getShortAddress'

import { VITE_EXPLORER_URL } from '../../constants/config'
import CapacityCommitmentGQL from '../../store/CapacityCommitmentGQL'
import { A } from '../A'
import { Copyable } from '../Copyable'
import { Flex } from '../Flex'
import HintedText from '../HintedText'
import { Text } from '../Text'

import { CapacityCommitmentInformation, InformationRow } from './styled'

export function ProviderInfo({
  capacityCommitment,
}: {
  capacityCommitment: CapacityCommitmentGQL
}) {
  if (!capacityCommitment.provider) return null

  return (
    <CapacityCommitmentInformation>
      <Text size={16}>Provider information</Text>
      <InformationRow>
        <HintedText text="Provider ID" />
        <Flex alignItems="center">
          <A
            size={12}
            href={`${VITE_EXPLORER_URL}/provider/${capacityCommitment.provider.id}`}
          >
            {getShortAddress(capacityCommitment.provider.id, 16)}
          </A>
          <Copyable value={capacityCommitment.provider.id} />
        </Flex>
      </InformationRow>
      <InformationRow>
        <HintedText
          text="Provider lifetime"
          hint="How long this provider has been active in the Fluence network"
        />
        <Text size={12}>
          {formatDistanceToNowStrict(
            new Date((capacityCommitment.provider.createdAt ?? 0) * 1000),
          )}
        </Text>
      </InformationRow>
      <InformationRow>
        <HintedText
          text="Total CCs over time"
          hint="Number of Capacity Commitments this provider has created. View full history at Fluence Explorer"
        />
        <Text size={12}>{capacityCommitment.provider.totalCCs}</Text>
      </InformationRow>
    </CapacityCommitmentInformation>
  )
}
