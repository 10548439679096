import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Colors, colors } from '../constants/colors'

export interface TextProps {
  size?: number
  weight?: number
  color?: Colors
  transform?: 'none' | 'uppercase' | 'capitalize'
  align?: string
}

export const Text = styled.span<TextProps>(
  ({
    size,
    weight = 500,
    color = 'black900',
    transform = 'none',
    align = 'left',
  }) => css`
    ${size ? `font-size: ${size}px;` : ''}
    font-weight: ${weight};
    color: ${colors[color]};
    text-align: ${align};
    text-transform: ${transform};
  `,
)

export interface UppercaseTextProps {
  size?: number
  weight?: number
  color?: Colors
  align?: string
  onlyFirst?: boolean
}

export const UppercaseText = styled.span<UppercaseTextProps>(
  ({
    size = 16,
    weight = 500,
    color = 'black900',
    align = 'left',
    onlyFirst = false,
  }) => css`
    font-size: ${size}px;
    font-weight: ${weight};
    color: ${colors[color]};
    text-align: ${align};
    ${onlyFirst
      ? `
      &::first-letter {
        text-transform: uppercase;
      }
      `
      : `text-transform: uppercase;`}
  `,
)

export const ShrinkText = styled(Text)`
  text-overflow: ellipsis;
`

export const TextWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`

export const TextWithBadge = styled(TextWithIcon)`
  gap: 8px;
`
