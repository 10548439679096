import styled from '@emotion/styled'

import { Button } from './Button'

const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  color: #333;
  text-align: center;
  height: 100vh;
  text-wrap: balance;

  @media (max-width: 783px) {
    padding: 1rem;
  }
`

const ErrorTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  max-width: 80%;

  @media (max-width: 783px) {
    font-size: 2rem;
    max-width: 100%;
  }
`

const ErrorText = styled.p`
  font-size: 1.25rem;
  max-width: 800px;
  margin-bottom: 1.5rem;
  line-height: 1.5rem;
`

export function ErrorPage() {
  function handleReload() {
    window.location.reload()
  }

  return (
    <ErrorMessageContainer>
      <ErrorTitle>
        Oops! Looks like there’s a problem loading staking information.
      </ErrorTitle>
      <ErrorText>
        No need to worry—your funds are completely safe and secure on the
        Fluence blockchain. Please try again in a little bit!
      </ErrorText>
      <Button variant="outlineShadow" onClick={handleReload}>
        Reload Page
      </Button>
    </ErrorMessageContainer>
  )
}
