import { useContext } from 'react'

import { useCurrentCollateral } from '../../hooks/useCurrentCollateral'

import { CapacityCommitmentFunded } from '../../store/CapacityCommitmentFunded'
import HintedText from '../HintedText'
import { SignerContext } from '../SignerProvider'
import { Text } from '../Text'
import TokenBalance from '../TokenBalance'
import WithdrawCollateralButton from '../Withdraw/WithdrawCollateralButton'

import { ActionButtonContainer } from './styled'
import { CapacityCommitmentInformation, InformationRow } from './styled'

const CurrentCollateral = ({
  capacityCommitment,
}: {
  capacityCommitment: CapacityCommitmentFunded
}) => {
  const value = useCurrentCollateral(capacityCommitment)

  return value !== null && <TokenBalance balance={value} />
}

export function Collateral({
  capacityCommitment,
}: {
  capacityCommitment: CapacityCommitmentFunded
}) {
  const { signer } = useContext(SignerContext)

  return (
    <CapacityCommitmentInformation>
      <Text size={16}>Collateral</Text>
      {capacityCommitment.totalCollateral !== undefined && (
        <InformationRow>
          <HintedText
            text="Staked collateral"
            hint="The amount of FLT tokens staked for this Capacity Commitment"
          />
          <TokenBalance balance={capacityCommitment.totalCollateral} />
        </InformationRow>
      )}
      <InformationRow>
        <HintedText
          text="Current collateral"
          hint={
            <>
              The current value of the collateral. Equals to the staked one if
              not slashed.{' '}
              <strong>
                May be zero for already ended CCs with collateral withdrawn
              </strong>
            </>
          }
        />
        <CurrentCollateral capacityCommitment={capacityCommitment} />
      </InformationRow>
      {signer && (
        <ActionButtonContainer>
          <WithdrawCollateralButton capacityCommitment={capacityCommitment} />
        </ActionButtonContainer>
      )}
    </CapacityCommitmentInformation>
  )
}
