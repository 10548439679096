import { createContracts } from './createDealClient'

export async function getContractConstants() {
  const contracts = createContracts()

  const constants = await contracts.diamond.getAllConstants()
  const currentEpoch = await contracts.diamond.currentEpoch()

  const c1 = constants.constants1
  const c2 = constants.constants2

  const raw = {
    fltPrice: c1.fltPrice,
    fltCollateralPerUnit: c1.fltCollateralPerUnit,
    usdCollateralPerUnit: c1.usdCollateralPerUnit,
    usdTargetRevenuePerEpoch: c1.usdTargetRevenuePerEpoch,
    minDuration: c1.minDuration,
    maxDuration: c1.maxDuration,
    minRewardPerEpoch: c1.minRewardPerEpoch,
    maxRewardPerEpoch: c1.maxRewardPerEpoch,
    vestingPeriodDuration: c1.vestingPeriodDuration,
    vestingPeriodCount: c1.vestingPeriodCount,
    maxFailedRatio: c1.maxFailedRatio,
    epochDuration: c2.epochDuration,
    minDealRematchingEpoch: c2.minDealRematchingEpoch,
    minDealDepositedEpoch: c2.minDealDepositedEpoch,
    minCUPerCommitment: c2.minCUPerCommitment,
    maxCUPerCommitment: c2.maxCUPerCommitment,
    precision: c2.precision,
    initTimestamp: c2.initTimestamp,
    slashingRate: c1.slashingRate,
    minProofsPerEpoch: c1.minProofsPerEpoch,
    maxProofsPerEpoch: c1.maxProofsPerEpoch,
    withdrawEpochsAfterFailed: c1.withdrawEpochsAfterFailed,
    difficulty: c2.difficulty,
    currentEpoch: currentEpoch,
  }

  return {
    id: 'current',
    ...raw,
    raw,
  }
}
