import React, { useMemo } from 'react'
import styled from '@emotion/styled'

import { ROUTES, Routes } from '../constants'
import { colors } from '../constants/colors'
import { BRIDGE_URL, NFT_COLLECTION_URL } from '../constants/config'

import { ActiveLink } from './ActiveLink'

const MENU: {
  title: string
  link: Routes[keyof Routes]
  routes?: Routes[keyof Routes][]
  external?: boolean
}[] = [
  {
    title: 'Stake',
    link: ROUTES.stake,
    routes: [ROUTES.stake],
  },
  {
    title: 'Bridge',
    link: BRIDGE_URL,
    external: true,
  },
  {
    title: 'NFT collection',
    link: NFT_COLLECTION_URL,
    external: true,
  },
]

export const Menu: React.FC<{
  onClick?: (e: React.MouseEvent) => void
}> = ({ onClick }) => {
  const links = useMemo(() => {
    return MENU.map((item) =>
      item.external ? (
        <StyledLink
          href={item.link}
          key={item.title}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClick}
        >
          {item.title}
        </StyledLink>
      ) : (
        <StyledActiveLink
          href={item.link}
          key={item.title}
          routes={item.routes}
          onClick={onClick}
        >
          {item.title}
        </StyledActiveLink>
      ),
    )
  }, [])

  return <MenuBlock>{links}</MenuBlock>
}

export const MenuBlock = styled.div`
  display: flex;
  gap: 40px;
  font-size: 16px;
`

export const StyledLink = styled.a`
  font-size: 16px;
  color: ${colors.grey400};
  cursor: pointer;

  &:hover {
    color: ${colors.black900};
  }
`

export const StyledActiveLink = styled(ActiveLink)`
  font-size: 16px;
`
