import styled from '@emotion/styled'

export const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 36px;
`

export const MessageBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  max-width: 500px;
`
