import { useCallback } from 'react'
import styled from '@emotion/styled'

import { Box, Flex } from '../Flex'
import { Pagination, PaginationProps } from '../Pagination'
import { Select } from '../Select'

const pages = [5, 10, 20, 50]
  .map(String)
  .map((value) => ({ label: `${value} records`, value }))

export function TableFooter({
  countItems,
  onSetCountItems,
  ...props
}: PaginationProps & {
  countItems: number
  onSetCountItems: (countItems: number) => void
}) {
  const onChangeCount = useCallback(
    (value: string) => onSetCountItems(Number(value)),
    [onSetCountItems],
  )

  if (props.pages <= 0) return null

  return (
    <Row justifyContent="space-between" gap="8px" position="relative">
      <Box gap="8px" alignItems="center" alignSelf="start">
        <Select
          items={pages}
          value={String(countItems)}
          onChange={onChangeCount}
        />
      </Box>
      <Box>{props.pages > 1 && <Pagination {...props} />}</Box>
    </Row>
  )
}

const Row = styled(Flex)`
  @media (width <= 768px) {
    flex-direction: column;

    & > ${Box} {
      justify-content: flex-end;
      width: 100%;
      display: flex;
    }
  }
`
