import { Suspense } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Flex } from 'reflexbox'

import { useConstants } from '../../hooks/useConstants'
import { formatUnixTimestamp } from '../../utils/formatUnixTimestamp'
import { getShortAddress } from '../../utils/getShortAddress'

import { VITE_EXPLORER_URL } from '../../constants/config'
import CapacityCommitmentGQL from '../../store/CapacityCommitmentGQL'
import { A } from '../A'
import { Copyable } from '../Copyable'
import HintedText from '../HintedText'
import { Text } from '../Text'

import { CapacityCommitmentInformation, InformationRow } from './styled'

function TotalPossibleProofs({
  capacityCommitment,
}: {
  capacityCommitment: CapacityCommitmentGQL
}) {
  const constants = useConstants()

  if (!constants) return null

  const totalPossibleProofs =
    constants.capacityMaxFailedRatio * capacityCommitment.computeUnitsCount

  return (
    <Text size={12}>
      {capacityCommitment.totalFailCount} / {totalPossibleProofs}
    </Text>
  )
}

export function CUsStats({
  capacityCommitment,
}: {
  capacityCommitment: CapacityCommitmentGQL
}) {
  const constants = useConstants()

  if (!constants) return null

  return (
    <CapacityCommitmentInformation>
      <Text size={16}>Statistics</Text>
      <InformationRow>
        <HintedText
          text="Total CUs"
          hint="Number of Compute Units (CU) in this Capacity Commitment (CC)"
        />
        <Text size={12}>{capacityCommitment.computeUnitsCount}</Text>
      </InformationRow>
      <InformationRow>
        <HintedText
          text="Staked at"
          hint="The date when this Capacity Commitment was staked"
        />
        <Text size={12}>
          {
            formatUnixTimestamp(
              constants.calculateTimestamp(capacityCommitment.startEpoch),
            ).date
          }
        </Text>
      </InformationRow>
      <InformationRow>
        <HintedText
          text="Expiration"
          hint="The period during which the CC will remain active and earn rewards, unless it fails earlier"
        />
        <Text size={12}>
          {
            formatUnixTimestamp(
              constants.calculateTimestamp(capacityCommitment.endEpoch),
            ).date
          }
        </Text>
      </InformationRow>
      <InformationRow>
        <HintedText
          text="Missed proofs / Threshold"
          hint="How many times this CC’s CUs failed to submit Proofs; How many failures are allowed before it's considered failed"
        />
        <Suspense fallback={<Skeleton width="60px" height="12px" />}>
          <TotalPossibleProofs capacityCommitment={capacityCommitment} />
        </Suspense>
      </InformationRow>
      <InformationRow>
        <HintedText text="Commitment ID" />
        <Flex alignItems="center">
          <A
            size={12}
            href={`${VITE_EXPLORER_URL}/capacity/${capacityCommitment.id}`}
          >
            {getShortAddress(capacityCommitment.id, 16)}
          </A>
          <Copyable value={capacityCommitment.id} />
        </Flex>
      </InformationRow>
    </CapacityCommitmentInformation>
  )
}
