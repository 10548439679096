import { Entity } from '@data-client/rest'

export class CapacityCommitmentBasic extends Entity {
  readonly id!: string
  readonly failedEpoch!: number
  readonly totalFailCount!: number

  pk() {
    return this.id.toLowerCase()
  }
}
