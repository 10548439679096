import { Endpoint } from '@data-client/rest'

import { createContracts } from '../utils/createDealClient'
import { renderJsonString } from '../utils/renderJsonString'

import { CapacityCommitmentBasic } from './CapacityCommitmentBasic'

export const getCapacityCommitmentBasic = new Endpoint(
  async ({ id }: { id: string }) => {
    const contracts = createContracts()
    const capacityCommitment = await contracts.diamond.getCommitment(id)
    const failedEpoch = Number(capacityCommitment.failedEpoch)
    const totalFailCount = Number(capacityCommitment.totalFailCount)

    return {
      id,
      failedEpoch,
      totalFailCount,
    }
  },
  {
    key: (params: unknown) =>
      `getCapacityCommitmentBasic ${renderJsonString(params)}`,
    name: 'getCapacityCommitmentBasic',
    schema: CapacityCommitmentBasic,
  },
)
