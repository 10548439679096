import { createContext, ReactNode, useMemo } from 'react'
import { JsonRpcSigner } from 'ethers'

import { useEthersSigner } from '../hooks/useEthersSigner'

export const SignerContext = createContext<{
  signer?: JsonRpcSigner
}>({})

export default function SignerProvider({ children }: { children: ReactNode }) {
  const signer = useEthersSigner()
  const signerContextValue = useMemo(() => ({ signer }), [signer])

  return (
    <SignerContext.Provider value={signerContextValue}>
      {children}
    </SignerContext.Provider>
  )
}
