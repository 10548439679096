import React from 'react'
import { AsyncBoundary, ErrorBoundary } from '@data-client/react'
import styled from '@emotion/styled'

import { ErrorPage } from './ErrorPage'
import { Header } from './Header'

export const Layout: React.FC<{
  children: React.ReactNode | React.ReactNode[]
}> = ({ children }) => {
  return (
    <LayoutBlock>
      <Header />
      <ErrorBoundary fallbackComponent={() => <ErrorPage />}>
        <AsyncBoundary errorComponent={() => <ErrorPage />}>
          <Content>{children}</Content>
        </AsyncBoundary>
      </ErrorBoundary>
    </LayoutBlock>
  )
}

const LayoutBlock = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`
