import { GQLEntity } from '@data-client/graphql'
import Big from 'big.js'
import { print } from 'graphql'

import { nativeTokenToBig } from '../utils/nativeTokenToBig'

import { CCsRewardDocument } from '../generated/graphql'

import { gql } from './qql'

export default class CCsReward extends GQLEntity {
  readonly stakerTotalCapacityRewards!: Big
  readonly stakerUnlockedCapacityRewards!: Big
  readonly stakerWithdrawnCapacityRewards!: Big
  readonly totalCapacityRewards!: number
  readonly totalDealStakerRewards!: number
  readonly unlockedCapacityRewards!: number
  readonly unlockedDealStakerRewards!: number
  readonly withdrawnCapacityRewards!: number
  readonly withdrawnDealStakerRewards!: number

  get availableToClaim() {
    const current = this.stakerUnlockedCapacityRewards.minus(
      this.stakerWithdrawnCapacityRewards,
    )
    return current.lt(0) ? Big(0) : current
  }

  static schema = {
    stakerTotalCapacityRewards: nativeTokenToBig,
    stakerUnlockedCapacityRewards: nativeTokenToBig,
    stakerWithdrawnCapacityRewards: nativeTokenToBig,
  }
}

export const CCsRewardQuery = gql.query(print(CCsRewardDocument), {
  ccsReward: CCsReward,
})
