import { Endpoint, Entity } from '@data-client/rest'
import Big from 'big.js'

import { fixedPrecisionValueToBig } from '../utils/fixedPrecisionValueToBig'
import { formatDuration } from '../utils/formatDuration'
import { getContractConstants } from '../utils/getContractConstants'
import { nativeTokenToBig } from '../utils/nativeTokenToBig'

export class Constants extends Entity {
  readonly id = 'current'
  readonly precision!: number
  readonly fltPrice!: Big
  readonly fltCollateralPerUnit!: Big
  readonly maxFailedRatio!: number
  readonly epochDuration!: number
  readonly slashingRate!: bigint
  readonly initTimestamp!: number
  readonly currentEpoch!: number
  readonly withdrawEpochsAfterFailed!: number

  readonly usdCollateralPerUnit!: Big
  readonly usdTargetRevenuePerEpoch!: Big
  readonly minDuration!: bigint
  readonly maxDuration!: bigint
  readonly minRewardPerEpoch!: Big
  readonly maxRewardPerEpoch!: Big
  readonly vestingPeriodDuration!: bigint
  readonly vestingPeriodCount!: bigint
  readonly minDealRematchingEpoch!: bigint
  readonly minDealDepositedEpoch!: bigint
  readonly minCUPerCommitment!: bigint
  readonly maxCUPerCommitment!: bigint
  readonly minProofsPerEpoch!: bigint
  readonly maxProofsPerEpoch!: bigint
  readonly difficulty!: string

  pk() {
    return this.id
  }

  static schema = {
    usdTargetRevenuePerEpoch: fixedPrecisionValueToBig,
    fltPrice: fixedPrecisionValueToBig,
    fltCollateralPerUnit: nativeTokenToBig,
    usdCollateralPerUnit: fixedPrecisionValueToBig,
    minRewardPerEpoch: nativeTokenToBig,
    maxRewardPerEpoch: nativeTokenToBig,
    maxFailedRatio: Number,
    withdrawEpochsAfterFailed: Number,
    precision: Number,
    initTimestamp: Number,
    epochDuration: Number,
    currentEpoch: Number,
  }

  calculateTimestamp(epoch: number) {
    return (epoch - 1) * this.epochDuration + this.initTimestamp
  }

  readonly raw: object = {}

  get formatted() {
    return {
      fltPrice: this.fltPrice.toNumber(),
      fltCollateralPerUnit: this.fltCollateralPerUnit.toNumber(),
      usdCollateralPerUnit: this.usdCollateralPerUnit.toNumber(),
      usdTargetRevenuePerEpoch: this.usdTargetRevenuePerEpoch.toNumber(),
      minDuration: Number(this.minDuration),
      maxDuration: Number(this.maxDuration),
      minRewardPerEpoch: this.minRewardPerEpoch.toNumber(),
      maxRewardPerEpoch: this.maxRewardPerEpoch.toNumber(),
      vestingPeriodDuration: Number(this.vestingPeriodDuration),
      vestingPeriodCount: Number(this.vestingPeriodCount),
      maxFailedRatio: Number(this.maxFailedRatio),
      epochDuration: formatDuration(Number(this.epochDuration) * 1000),
      minDealRematchingEpoch: Number(this.minDealRematchingEpoch),
      minDealDepositedEpoch: Number(this.minDealDepositedEpoch),
      minCUPerCommitment: Number(this.minCUPerCommitment),
      maxCUPerCommitment: Number(this.maxCUPerCommitment),
      precision: Number(this.precision),
      initTimestamp: Number(this.initTimestamp),
      slashingRate: Number(this.slashingRate),
      minProofsPerEpoch: Number(this.minProofsPerEpoch),
      maxProofsPerEpoch: Number(this.maxProofsPerEpoch),
      withdrawEpochsAfterFailed: Number(this.withdrawEpochsAfterFailed),
      difficulty: this.difficulty,
    }
  }
}

export const getConstants = new Endpoint(getContractConstants, {
  errorPolicy: () => 'hard',
  name: 'getConstants',
  schema: Constants,
  pollFrequency: 10000,
  dataExpiryLength: 1000 * 60 * 60,
})
