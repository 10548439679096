import styled from '@emotion/styled'
import { ConnectButton } from '@rainbow-me/rainbowkit'

import { MainnetIcon, TestnetIcon } from '../assets/icons'
import { getShortAddress } from '../utils/getShortAddress'

import { Button } from './Button'
import { chains } from './chains'
import NetworkBadge from './NetworkBadge'
import { UppercaseText } from './Text'

export default function ConnectAccount({
  className,
  showNetwork,
}: {
  className?: string
  showNetwork?: boolean
}) {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== 'loading'
        const connected = ready && account && chain

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Button
                    className={className}
                    variant="outlineShadow"
                    onClick={(e) => {
                      e.stopPropagation()
                      openConnectModal()
                    }}
                  >
                    <UppercaseText size={10} weight={700}>
                      Connect Wallet
                    </UppercaseText>
                  </Button>
                )
              }

              if (chain.unsupported && showNetwork) {
                return (
                  <ButtonContainer>
                    <Button
                      className={className}
                      variant="outlineShadow"
                      onClick={(e) => {
                        e.stopPropagation()
                        openChainModal()
                      }}
                    >
                      <UppercaseText size={10} color="red" weight={700}>
                        Switch network
                        <br />
                        <UppercaseText color="grey500" size={10}>
                          {chains[chain.id]}
                        </UppercaseText>
                      </UppercaseText>
                    </Button>
                  </ButtonContainer>
                )
              }

              return (
                <ButtonContainer>
                  {chain.name && showNetwork && (
                    <NetworkBadge
                      name={chain.name}
                      openChainModal={(e) => {
                        e.stopPropagation()
                        openChainModal()
                      }}
                      icon={
                        chain.name === 'mainnet' || chain.name === 'kras' ? (
                          <MainnetIcon />
                        ) : (
                          <TestnetIcon />
                        )
                      }
                    />
                  )}
                  <Button
                    className={className}
                    variant="outlineShadow"
                    onClick={(e) => {
                      e.stopPropagation()
                      openAccountModal()
                    }}
                  >
                    <UppercaseText size={10} weight={700}>
                      {getShortAddress(account.address, 9, 9)}
                    </UppercaseText>
                  </Button>
                </ButtonContainer>
              )
            })()}
          </div>
        )
      }}
    </ConnectButton.Custom>
  )
}

export const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  gap: 6px;
  align-items: center;
`
