import { createContext, ReactNode, useCallback, useMemo, useState } from 'react'

export type StakingStatus = 'waiting' | 'pending' | 'success' | 'failed'

interface StakeModalData {
  id: string
  title: string
  status: StakingStatus
  transactionHash?: string
}

export const StakeModalContext = createContext<{
  data: StakeModalData | null
  setData: (data: StakeModalData) => void
  status?: StakingStatus
  setStatus: (id: string, status: StakingStatus) => void
  setCurrentId: (id: string) => void
}>({
  data: null,
  setData: () => {},
  setStatus: () => {},
  setCurrentId: () => {},
})

export default function StakeModalProvider({
  children,
}: {
  children: ReactNode
}) {
  const [store, setStore] = useState<{
    [key: string]: StakeModalData | null
  }>({})
  const [currentId, setCurrentId] = useState('')

  const update = useCallback(
    (id: string, data: StakeModalData | null) => {
      if (id) setCurrentId(id)
      setStore({ ...store, [id]: data })
    },
    [setCurrentId, setStore, store],
  )

  const current = store[currentId] || null

  const value = useMemo(
    () => ({
      data: current,
      status: current?.status,
      setData: (data: StakeModalData) => update(data.id, data),
      setStatus: (id: string, status: StakingStatus) => {
        const record = store[id]
        if (record && id) update(id, { ...record, status })
      },
      setCurrentId,
    }),
    [store, current, update, setCurrentId],
  )

  return (
    <StakeModalContext.Provider value={value}>
      {children}
    </StakeModalContext.Provider>
  )
}
