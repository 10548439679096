import React from 'react'
import styled from '@emotion/styled'

import { Logo } from '../assets/icons'

import { ROUTES } from '../constants'
import { colors } from '../constants/colors'

import { A } from './A'
import { Menu, MenuBlock } from './Menu'
import { RightMenu } from './RightMenu'
import { Text } from './Text'

export const Header: React.FC = () => {
  return (
    <HeaderBackgroundBlock>
      <HeaderBlock>
        <A href={ROUTES.stake}>
          <Text color="black900">
            <Logo />
          </Text>
        </A>
        <Menu />
        <RightMenu />
      </HeaderBlock>
    </HeaderBackgroundBlock>
  )
}

const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 16px 24px;

  @media (max-width: 600px) {
    ${MenuBlock} {
      display: none;
    }
  }
`
const HeaderBackgroundBlock = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: ${colors.grey100};
  min-height: 180px;

  @media (max-width: 900px) {
    min-height: 66px;
  }
`
