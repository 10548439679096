import React, { ReactElement, Suspense, useEffect, useState } from 'react'
import { useCache, useSuspense } from '@data-client/react'
import * as Accordion from '@radix-ui/react-accordion'

import { Flex } from '../../components/Flex'
import { StyledHint } from '../../components/Hint'
import { MessageBlock, MessageContainer } from '../../components/Message'
import { Select } from '../../components/Select'
import {
  TableBody,
  TableColumnTitle,
  TableColumnTitleWithSort,
  TableHeader,
  TableResponsive,
  TableResponsiveWrapper,
} from '../../components/Table'
import { TableFooter } from '../../components/Table/TableFooter'
import { Text, UppercaseText } from '../../components/Text'
import { usePagination } from '../../hooks'
import { SetFilter } from '../../hooks/useFilters'
import { usePrevious } from '../../hooks/usePrevious'

import {
  CapacityCommitment_OrderBy,
  CapacityCommitmentStatus,
  OrderDirection,
} from '../../generated/graphql'
import { CapacityCommitmentFunded } from '../../store/CapacityCommitmentFunded'
import {
  CapacityCommitmentFundedQuery,
  setupFundedVariables,
} from '../../store/CapacityCommitmentFundedQuery'
import { STATUS_NAMES } from '../../store/CapacityCommitmentGQL'

import { StatusContainer } from './styled'

const CAPACITY_COMMITMENTS_PER_PAGE = 5

export type CapacityCommitmentSort =
  `${CapacityCommitment_OrderBy}:${OrderDirection}`

const STATUS_ITEMS: {
  value: CapacityCommitmentStatus | 'all'
  label: string
}[] = [
  { value: 'all', label: 'All' },
  { value: CapacityCommitmentStatus.Active, label: STATUS_NAMES['Active'] },
  { value: CapacityCommitmentStatus.Inactive, label: STATUS_NAMES['Inactive'] },
  { value: CapacityCommitmentStatus.Failed, label: STATUS_NAMES['Failed'] },
  { value: CapacityCommitmentStatus.Removed, label: STATUS_NAMES['Removed'] },
  {
    value: CapacityCommitmentStatus.WaitStart,
    label: STATUS_NAMES['WaitStart'],
  },
]

const Suspended: React.FC<{
  statusFilter: CapacityCommitmentStatus | 'all'
  isLoading?: boolean
  columns: string[][][]
  stakerId?: string
  params: object
  onExpand: (id: string) => void
  renderRow: (params: {
    capacityCommitment: CapacityCommitmentFunded
    onExpand: (id: string) => void
    columns: string[][][]
  }) => ReactElement
  page: number
  countItems: number
}> = ({
  page,
  countItems,
  onExpand,
  statusFilter,
  isLoading,
  renderRow,
  columns,
  stakerId,
  params,
}) => {
  const { capacityCommitments } = useSuspense(
    CapacityCommitmentFundedQuery,
    params,
  )

  return (
    <TableBody
      skeletonCount={CAPACITY_COMMITMENTS_PER_PAGE}
      skeletonHeight={40}
      isLoading={isLoading}
      isEmpty={capacityCommitments?.length === 0}
      noDataView={
        <MessageContainer>
          <MessageBlock>
            <Text color="black900" size={18}>
              {stakerId && statusFilter === 'all'
                ? 'You haven’t staked any Capacity Commitments yet'
                : 'Not found Capacity Commitments'}
            </Text>
          </MessageBlock>
        </MessageContainer>
      }
    >
      {capacityCommitments
        ?.slice(page * countItems, page * countItems + countItems)
        ?.map((capacityCommitment) =>
          renderRow({ capacityCommitment, onExpand, columns }),
        )}
    </TableBody>
  )
}

export const CapacityCommitmentTable: React.FC<{
  filters: { statuses: CapacityCommitmentStatus[]; searchBy?: string }
  setFilter: SetFilter<{ statuses: CapacityCommitmentStatus[] }>
  setOrder: (order: CapacityCommitmentSort) => void
  orderType: OrderDirection
  orderBy: CapacityCommitment_OrderBy
  isLoading?: boolean
  columns: string[][][]
  stakerId?: string
  renderRow: (params: {
    capacityCommitment: CapacityCommitmentFunded
    onExpand: (id: string) => void
    columns: string[][][]
  }) => ReactElement
}> = ({
  setFilter,
  setOrder,
  isLoading,
  filters,
  orderType,
  orderBy,
  renderRow,
  columns,
  stakerId,
}) => {
  const [ccPerPage, setCCPerPage] = useState(CAPACITY_COMMITMENTS_PER_PAGE)
  const { page, selectPage, limit, offset, getTotalPages } =
    usePagination(ccPerPage)

  const params = setupFundedVariables({
    ...filters,
    orderBy,
    orderType,
    stakerId,
  })

  const { capacityCommitments } = useCache(
    CapacityCommitmentFundedQuery,
    params,
  )

  const totalPages = getTotalPages(capacityCommitments?.length)

  const [isAccordionOpen, setIsAccordionOpen] = useState<string[]>([])

  const prevStakerId = usePrevious(stakerId)
  const prevFilters = usePrevious(filters)
  const prevCCPerPage = usePrevious(ccPerPage)
  useEffect(() => {
    if (
      prevStakerId !== stakerId ||
      prevFilters !== filters ||
      prevCCPerPage !== ccPerPage
    ) {
      selectPage(1)
    }
  }, [
    stakerId,
    prevStakerId,
    selectPage,
    prevFilters,
    filters,
    prevCCPerPage,
    ccPerPage,
  ])

  const onExpand = (id: string) => {
    if (isAccordionOpen.includes(id)) {
      return setIsAccordionOpen((arr) => arr.filter((v) => v !== id))
    }

    setIsAccordionOpen([...isAccordionOpen, id])
  }

  const handleSort = (
    key: CapacityCommitment_OrderBy,
    order: OrderDirection,
  ) => {
    setOrder(`${key}:${order}`)
  }

  const handleSetStatus = (value: CapacityCommitmentStatus | 'all') => {
    const statuses =
      value === 'all'
        ? ([
            CapacityCommitmentStatus.WaitStart,
            CapacityCommitmentStatus.Active,
            CapacityCommitmentStatus.Inactive,
            CapacityCommitmentStatus.Failed,
            CapacityCommitmentStatus.Removed,
          ] as CapacityCommitmentStatus[])
        : [value]
    setFilter('statuses', statuses)
  }

  let statusFilter: CapacityCommitmentStatus | 'all' = 'all'
  if (filters?.statuses && filters.statuses[0]) {
    statusFilter = filters?.statuses.length > 1 ? 'all' : filters?.statuses[0]
  }

  const hasNextPage = totalPages * limit >= offset + limit

  return (
    <Flex gap="24px" flexDirection="column" mt="24px" mb="24px">
      <StatusContainer>
        <UppercaseText size={10} weight={600} color="grey400">
          Status
        </UppercaseText>
        <Select
          value={statusFilter}
          onChange={handleSetStatus}
          items={STATUS_ITEMS}
        />
      </StatusContainer>
      <TableResponsiveWrapper>
        <TableResponsive>
          <TableHeader template={columns}>
            <TableColumnTitle>Capacity Commitment Id</TableColumnTitle>
            <TableColumnTitle>Provider Name</TableColumnTitle>
            <TableColumnTitleWithSort
              order={orderType}
              field={CapacityCommitment_OrderBy.EndEpoch}
              isActive={orderBy === CapacityCommitment_OrderBy.EndEpoch}
              onSort={handleSort}
              hint="The period during which the CC will remain active and earn rewards, unless it fails earlier"
            >
              Expiration
            </TableColumnTitleWithSort>
            <TableColumnTitleWithSort
              order={orderType}
              field={CapacityCommitment_OrderBy.RewardDelegatorRate}
              isActive={
                orderBy === CapacityCommitment_OrderBy.RewardDelegatorRate
              }
              onSort={handleSort}
              hint="The share of rewards the staker earns for Capacity Commitment, defined by provider"
            >
              Staking Reward
            </TableColumnTitleWithSort>
            <StyledHint content="The current status of the CC">
              <TableColumnTitle>Status</TableColumnTitle>
            </StyledHint>
          </TableHeader>
          <Accordion.Root type="multiple" value={isAccordionOpen}>
            <Suspense
              fallback={
                <TableBody
                  skeletonCount={ccPerPage}
                  skeletonHeight={40}
                  isLoading
                ></TableBody>
              }
            >
              <Suspended
                onExpand={onExpand}
                statusFilter={statusFilter}
                isLoading={isLoading}
                renderRow={renderRow}
                columns={columns}
                stakerId={stakerId}
                params={params}
                page={page - 1}
                countItems={ccPerPage}
              />
            </Suspense>
          </Accordion.Root>
        </TableResponsive>
      </TableResponsiveWrapper>
      {capacityCommitments && (
        <TableFooter
          countItems={ccPerPage}
          onSetCountItems={setCCPerPage}
          pages={totalPages}
          page={page}
          hasNextPage={hasNextPage}
          onSelect={selectPage}
        />
      )}
    </Flex>
  )
}
