import React, { ReactNode, useContext } from 'react'
import { useAccount } from 'wagmi'

import CapacityCommitmentGQL, {
  CommitmentLifecycleStatus,
} from '../../store/CapacityCommitmentGQL'
import { Button } from '../Button'
import { ButtonContainer } from '../CapacityCommitment/styled'
import ConnectAccount from '../ConnectAccount'
import { SignerContext } from '../SignerProvider'
import { StakeModalContext } from '../StakeModalProvider'
import { Text, UppercaseText } from '../Text'

interface StakeInfoProps {
  capacityCommitment: CapacityCommitmentGQL
}

export function useStakeButton({
  capacityCommitment,
}: {
  capacityCommitment: CapacityCommitmentGQL
}) {
  const { address } = useAccount()

  const isMine =
    capacityCommitment.staker?.toLowerCase() === address?.toLowerCase()

  if (!address)
    return {
      status: 'shouldConnect',
      title: 'Should connect',
      hint: 'Connect your wallet to stake tokens to Capacity commitment',
    }

  const isStaked =
    capacityCommitment?.status !== CommitmentLifecycleStatus.WaitDelegation

  if (isStaked)
    return {
      isStaked,
      status: 'staked',
      title: 'Staked',
      hint: `${isMine ? 'You' : 'Someone'} have already Staked for this Capacity
        commitment`,
    }

  const everyoneCanStake = capacityCommitment.staker === undefined
  const canStake = everyoneCanStake || isMine

  return {
    status: 'success',
    title: 'Stake',
    hint: canStake
      ? 'Review the terms of capacity commitment before staking FLT tokens'
      : 'This Capacity Commitment is reserved for a specific staker by its provider. Please choose an another one',
    disabled: !canStake,
  }
}

export const StakeInfoConnect = ({ hint }: { hint: ReactNode }) => {
  return (
    <ButtonContainer>
      <ConnectAccount />
      <Text size={12} color="grey400" align="center">
        {hint}
      </Text>
    </ButtonContainer>
  )
}

export const StakeButton = ({
  children,
  disabled,
  staked,
  capacityCommitment,
  className,
}: {
  className?: string
  children?: ReactNode
  disabled?: boolean
  staked?: boolean
  capacityCommitment: CapacityCommitmentGQL
}) => {
  const { setData } = useContext(StakeModalContext)
  const { signer } = useContext(SignerContext)

  const onOpenModal = async (e: React.MouseEvent) => {
    e.stopPropagation()

    if (disabled || !signer) return

    setData({
      status: 'waiting',
      title: '',
      id: capacityCommitment.id,
    })
  }

  return (
    <Button
      onClick={onOpenModal}
      variant={staked ? 'success' : disabled ? 'grey' : 'black'}
      disabled={disabled}
      className={className}
    >
      <UppercaseText
        size={10}
        weight={700}
        color={staked ? 'green' : disabled ? 'grey400' : 'white'}
      >
        {children}
      </UppercaseText>
    </Button>
  )
}

export const StakeInfo: React.FC<StakeInfoProps> = ({ capacityCommitment }) => {
  const { status, title, hint, disabled, isStaked } = useStakeButton({
    capacityCommitment,
  })

  if (status === 'shouldConnect') {
    return <StakeInfoConnect hint={hint} />
  }

  return (
    <ButtonContainer>
      <StakeButton
        capacityCommitment={capacityCommitment}
        disabled={disabled}
        staked={isStaked}
      >
        {title}
      </StakeButton>
      {hint && (
        <Text size={12} color="grey400" align="center">
          {hint}
        </Text>
      )}
    </ButtonContainer>
  )
}
