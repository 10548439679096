import { ReactNode } from 'react'
import styled from '@emotion/styled'

import { InfoOutlineIcon } from '../assets/icons'

import { Text } from './Text'
import { Tooltip } from './Tooltip'

export function Hint({
  children = <InfoOutlineIcon />,
  content,
  className,
}: {
  children?: ReactNode
  content: ReactNode
  className?: string
}) {
  return (
    <Tooltip className={className} trigger={children}>
      <Text color="grey600" weight={600} size={10}>
        {content}
      </Text>
    </Tooltip>
  )
}

export const StyledHint = styled(Hint)`
  align-items: center;
`
