import { Suspense, useMemo } from 'react'
import { useDLE } from '@data-client/react'

import { CapacityCommitmentFundedQuery } from '../../store/CapacityCommitmentFundedQuery'
import CCCount from '../../store/CCStats'

import { CCCountStatsBase } from './CCCountStats'

function CCStakerCountStatsContainer({ stakerId }: { stakerId: string }) {
  const { data, loading } = useDLE(CapacityCommitmentFundedQuery, {
    filters: {
      and: [
        {
          delegator: stakerId,
        },
      ],
    },
  })

  const statusSummary = useMemo(
    () =>
      CCCount.fromJS({
        statuses: data.capacityCommitments?.reduce(
          (acc, cc) => {
            if (!cc.status) return acc
            acc[cc.status] = (acc[cc.status] || 0) + 1
            return acc
          },
          {
            WaitStart: 0,
            Active: 0,
            Inactive: 0,
            Failed: 0,
            Removed: 0,
            WaitDelegation: 0,
            Withdrawn: 0,
            Unknown: 0,
          },
        ),
      }),
    [data?.capacityCommitments],
  )

  return <CCCountStatsBase isLoading={loading} statusSummary={statusSummary} />
}

export function CCStakerCountStats({ stakerId }: { stakerId: string }) {
  return (
    <Suspense fallback={<CCCountStatsBase isLoading />}>
      <CCStakerCountStatsContainer stakerId={stakerId} />
    </Suspense>
  )
}
