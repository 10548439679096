import styled from '@emotion/styled'
import { formatDistanceToNowStrict } from 'date-fns'

import { useConstants } from '../../hooks/useConstants'
import { useExpectedAPR } from '../../hooks/useExpectedAPR'
import { formatPercent } from '../../utils/formatPercent'

import { colors } from '../../constants/colors'
import CapacityCommitmentGQL, {
  CommitmentLifecycleStatus,
} from '../../store/CapacityCommitmentGQL'
import { CapacityStatus } from '../CapacityStatus'
import ConnectAccount from '../ConnectAccount'
import { Space } from '../Space'
import { StakeButton, useStakeButton } from '../Stake'
import { Card, CardRow, Cell } from '../Table'
import { Text, UppercaseText } from '../Text'

import { ExplorerCell, ProviderCell } from './CapacityCommitmentLine'
import { Caret } from './Caret'

export const SmallStakeButton = styled(StakeButton)<{
  disabled?: boolean
  staked?: boolean
}>`
  ${({ disabled, staked }) => `
  height: 24px;
  border-radius: 6px;

  ${!staked && `background: ${disabled ? colors['grey200'] : colors['purpleGradient']}`};
`}
`

export const SmallConnectAccount = styled(ConnectAccount)`
  height: 24px;
  border-radius: 6px;
`

export function CapacityCommitmentCard({
  staking,
  capacityCommitment,
  onExpand,
}: {
  onExpand: () => void
  staking?: boolean
  capacityCommitment: CapacityCommitmentGQL
}) {
  const expectedAPR = useExpectedAPR(capacityCommitment)
  const constants = useConstants()

  const { disabled, isStaked, title, status } = useStakeButton({
    capacityCommitment,
  })

  if (!constants) return null

  const stakingDuration =
    capacityCommitment.duration * constants.coreEpochDuration * 1000

  return (
    <Card>
      {staking ? (
        <>
          <CardRow>
            <ProviderCell
              address={capacityCommitment.provider.id}
              name={capacityCommitment.provider.name}
              flexGrow={1}
            />
            <ExplorerCell
              capacityCommitment={capacityCommitment}
              left={10}
              right={10}
              justifyContent="end"
            />
            <Cell justifyContent="end">
              <Caret onToggle={onExpand} />
            </Cell>
          </CardRow>
          <CardRow justifyContent="space-between">
            <Cell flexDirection="column" alignItems="left">
              <Text size={12} align="left">
                ~{formatDistanceToNowStrict(Date.now() + stakingDuration)}
              </Text>
              <Space height="4px" />
              <Text size={10} color="grey600">
                Reward {formatPercent(capacityCommitment.stakingReward)} /
                Expected APR: {expectedAPR ? formatPercent(expectedAPR) : '-'}
              </Text>
            </Cell>
            <Cell gap="6px" justifyContent="end">
              {status === 'shouldConnect' ? (
                <SmallConnectAccount />
              ) : (
                <SmallStakeButton
                  disabled={disabled}
                  staked={isStaked}
                  capacityCommitment={capacityCommitment}
                >
                  {isStaked
                    ? title
                    : `Stake ${capacityCommitment.requiredCollateral.toFixed(1)} FLT`}
                </SmallStakeButton>
              )}
            </Cell>
          </CardRow>
        </>
      ) : (
        <>
          <CardRow>
            <ProviderCell
              address={capacityCommitment.provider?.id ?? ''}
              name={capacityCommitment.provider?.name ?? ''}
              flexGrow={1}
            />
            <ExplorerCell
              capacityCommitment={capacityCommitment}
              left={16}
              right={0}
              justifyContent="end"
            />
            <Cell justifyContent="end">
              <Caret onToggle={onExpand} />
            </Cell>
          </CardRow>
          <CardRow>
            <Cell flexGrow={1} flexDirection="column" alignItems="start">
              {capacityCommitment.status !== CommitmentLifecycleStatus.Failed &&
                capacityCommitment.endEpoch && (
                  <>
                    <UppercaseText size={12} onlyFirst>
                      {constants.calculateTimestamp(
                        capacityCommitment.endEpoch,
                      ) *
                        1000 >
                      Date.now()
                        ? 'Finish'
                        : 'Finished'}{' '}
                      {formatDistanceToNowStrict(
                        new Date(
                          constants.calculateTimestamp(
                            capacityCommitment.endEpoch,
                          ) * 1000,
                        ),
                        {
                          addSuffix: true,
                        },
                      )}
                    </UppercaseText>
                    <Space height="4px" />
                  </>
                )}
              <Text size={10} color="grey600">
                Reward {formatPercent(capacityCommitment.stakingReward)}
              </Text>
            </Cell>
            <Cell></Cell>
            <Cell>
              <CapacityStatus capacity={capacityCommitment} />
            </Cell>
          </CardRow>
        </>
      )}
    </Card>
  )
}
