import Big from 'big.js'

import { nativeTokenToBig } from '../utils/nativeTokenToBig'

import CapacityCommitmentGQL from './CapacityCommitmentGQL'

export enum CommitmentLifecycleStatus {
  Unknown = 'Unknown',
  Active = 'Active',
  Failed = 'Failed',
  Inactive = 'Inactive',
  Removed = 'Removed',
  WaitStart = 'WaitStart',
  Withdrawn = 'Withdrawn',
}

export class CapacityCommitmentFunded extends CapacityCommitmentGQL {
  readonly totalCollateral!: Big
  readonly collateralPerUnit!: Big
  readonly collateralWithdrawn!: boolean

  static schema = {
    ...CapacityCommitmentGQL.schema,
    totalCollateral: nativeTokenToBig,
    collateralPerUnit: nativeTokenToBig,
  }
}
