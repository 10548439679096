import CapacityCommitmentGQL from '../../store/CapacityCommitmentGQL'
import { StakeInfo } from '../Stake'

import { CapacityCommitmentInformation } from './styled'

export function StakeInfoCard({
  capacityCommitment,
}: {
  capacityCommitment: CapacityCommitmentGQL
}) {
  return (
    <CapacityCommitmentInformation>
      <StakeInfo capacityCommitment={capacityCommitment} />
    </CapacityCommitmentInformation>
  )
}
