import React, { CSSProperties } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from '@emotion/styled'

import { SortIcon } from '../../assets/icons'
import { setProp } from '../../utils/setProp'

import { colors } from '../../constants/colors'
import { OrderDirection } from '../../generated/graphql'
import { StyledHint } from '../Hint'
import { Text } from '../Text'

interface TableBodyProps {
  isLoading?: boolean
  isEmpty?: boolean
  skeletonCount?: number
  skeletonHeight?: number
  children?: React.ReactNode | React.ReactNode[]
  noDataView?: React.ReactNode
}

export const TableBody: React.FC<TableBodyProps> = ({
  isLoading,
  isEmpty,
  skeletonCount = 5,
  skeletonHeight = 48,
  children,
  noDataView,
}) => {
  if (isLoading) {
    return (
      <TableBodySkeleton>
        {Array.from(Array(skeletonCount)).map((_, i) => (
          <Skeleton key={i} height={skeletonHeight} />
        ))}
      </TableBodySkeleton>
    )
  }

  if (isEmpty) {
    return (
      <TableBodyStyled>
        <EmptyTable>
          {noDataView ? noDataView : <Text>No data</Text>}
        </EmptyTable>
      </TableBodyStyled>
    )
  }

  return <TableBodyStyled>{children}</TableBodyStyled>
}

interface TableColumnTitleWithSortProps<Field extends string> {
  order: OrderDirection
  field: Field
  isActive: boolean
  onSort: (field: Field, order: OrderDirection) => void
  children: React.ReactNode | React.ReactNode[]
  justifyContent?: CSSProperties['justifyContent']
  hint?: React.ReactNode
}

export const TableColumnTitleWithSort = <Field extends string>({
  isActive,
  order,
  field,
  onSort,
  children,
  justifyContent,
  hint,
}: TableColumnTitleWithSortProps<Field>) => {
  const handleSort = () => {
    onSort(
      field,
      order === OrderDirection.Desc ? OrderDirection.Asc : OrderDirection.Desc,
    )
  }

  return (
    <HeaderCellWithSort justifyContent={justifyContent}>
      <StyledHint content={hint}>
        <HeaderCellWithTooltip onClick={handleSort}>
          <TableColumnTitle>{children}</TableColumnTitle>

          <SortIconStyled isActive={isActive} type={order} />
        </HeaderCellWithTooltip>
      </StyledHint>
    </HeaderCellWithSort>
  )
}

const TableBodySkeleton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  line-height: 1;
`

const TableBodyStyled = styled.div<{ minWidth?: string }>`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const EmptyTable = styled.div`
  background-color: ${colors.grey100};
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TableHeader = styled.div<{
  template: string[][][]
  alignItems?: CSSProperties['alignItems']
}>`
  padding: 12px;
  display: grid;
  gap: 64px;
  align-items: ${({ alignItems }) => alignItems ?? 'center'};

  grid-template-columns: ${({ template }) => template[0][0].join(' ')};
  grid-template-rows: 12px;

  @media (max-width: 783px) {
    display: none;
    gap: 24px;
  }
`

export const HeaderCellWithTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`

const HeaderCellWithSort = styled.div<{
  justifyContent?: CSSProperties['justifyContent']
}>`
  display: flex;
  align-items: flex-start;
  ${setProp('justifyContent', 'justify-content')};
`

export const TableColumnTitle = styled(Text)<{
  wrap?: boolean
}>`
  color: ${colors.grey400};
  font-size: 10px;
  font-weight: 700;
  line-height: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};
`

export const RowBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.grey100};
  border-radius: 8px;
`

export const RowHeader = styled.div`
  width: 100%;
  cursor: pointer;
  padding: 12px 12px;
`

export const RowTrigger = styled.div`
  width: 100%;
`

export const Row = styled.div<{ template: string[][][] }>`
  width: fit-content;
  min-width: 100%;
  display: grid;
  grid-template-columns: ${({ template }) => template[0][0].join(' ')};
  grid-template-rows: ${({ template }) => template[0][1].join(' ')};
  gap: 64px;

  @media (max-width: 783px) {
    display: none;
    gap: 24px;
  }
`

export interface CellProps {
  justifyContent?: CSSProperties['justifyContent']
  flexDirection?: CSSProperties['flexDirection']
  alignItems?: CSSProperties['alignItems']
  gap?: CSSProperties['gap']
  flexGrow?: CSSProperties['flexGrow']
}

export const CardRow = styled.div<CellProps>`
  display: flex;
  width: 100%;
  gap: 8px;

  ${setProp('alignItems', 'align-items', 'center')};
  ${setProp('flexDirection', 'flex-direction')};
  ${setProp('justifyContent', 'justify-content')};
  ${setProp('gap', 'gap')};
  ${setProp('flexGrow', 'flex-grow')};
`

export const Card = styled.div`
  display: none;

  @media (max-width: 783px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
`

export const Cell = styled.div<CellProps>`
  display: flex;
  text-wrap: nowrap;

  ${setProp('alignItems', 'align-items', 'center')};
  ${setProp('flexDirection', 'flex-direction')};
  ${setProp('justifyContent', 'justify-content')};
  ${setProp('gap', 'gap')};
  ${setProp('flexGrow', 'flex-grow')};
`

export const SortIconStyled = styled(SortIcon)<{
  type: OrderDirection
  isActive?: boolean
}>`
  transform: ${({ type }) =>
    type === 'asc' ? 'rotate(-180deg)' : 'rotate(0deg)'};
  transition: transform 250ms ease-in-out;
  color: ${({ isActive = false }) => (isActive ? colors.blue : '#a1a1aa')};
`

export const TableResponsiveWrapper = styled.div`
  overflow-x: auto;
`

export const TableResponsive = styled.div<{ minWidth?: string }>`
  ${({ minWidth }) => `
  min-width: ${minWidth};
`}
`
