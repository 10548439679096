import React from 'react'

import { STATUS_COLORS } from '../constants/statusesMap'
import CapacityCommitmentGQL from '../store/CapacityCommitmentGQL'

import { Status } from './Status'
import { UppercaseText } from './Text'

interface CapacityStatusProps {
  capacity: CapacityCommitmentGQL
}

export const CapacityStatus: React.FC<CapacityStatusProps> = ({
  capacity,
}: CapacityStatusProps) => {
  const color = STATUS_COLORS[capacity.status]

  return (
    <Status color={color}>
      <UppercaseText color="white" weight={800} size={10}>
        {capacity.statusName}
      </UppercaseText>
    </Status>
  )
}
