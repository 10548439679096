import { useCurrentCollateral } from '../../hooks/useCurrentCollateral'
import { getShortAddress } from '../../utils/getShortAddress'

import { CapacityCommitmentFunded } from '../../store/CapacityCommitmentFunded'
import { CommitmentLifecycleStatus } from '../../store/CapacityCommitmentGQL'
import { Copyable } from '../Copyable'
import { WithdrawModal } from '../Modals/WithdrawModal'
import { Text } from '../Text'
import TokenBalance from '../TokenBalance'

import {
  RowWithdrawTotal,
  RowWithdrawTotalWrapper,
  WithdrawModalTitle,
} from './styled'

export function WithdrawFromCompletedCC({
  capacityCommitment,
  onClose,
  onWithdraw,
}: {
  onClose: () => void
  onWithdraw: () => void
  capacityCommitment: CapacityCommitmentFunded
}) {
  const currentCollateral = useCurrentCollateral(capacityCommitment)

  return (
    <WithdrawModal
      title={
        <WithdrawModalTitle>
          <Text size={20} color="grey500">
            You are about to withdraw collateral from capacity commitment
            with&nbsp;
            <Text
              size={20}
              color={
                capacityCommitment.status === CommitmentLifecycleStatus.Failed
                  ? 'red'
                  : 'green'
              }
            >
              {capacityCommitment.status === CommitmentLifecycleStatus.Failed
                ? CommitmentLifecycleStatus.Failed
                : 'Finished'}
            </Text>
            &nbsp;status
          </Text>
          <Copyable value={capacityCommitment.id}>
            <Text size={12} color="grey500">
              ID: {getShortAddress(capacityCommitment.id, 24)}
            </Text>
          </Copyable>
        </WithdrawModalTitle>
      }
      contentMinHeight="100px"
      isVisible
      onClose={onClose}
      onSubmit={onWithdraw}
    >
      <RowWithdrawTotalWrapper>
        <RowWithdrawTotal>
          <Text size={12} color="grey500" weight={500}>
            {currentCollateral
              ? 'Collateral to withdraw'
              : `Don't able to withdraw right now`}
          </Text>
          {currentCollateral && <TokenBalance balance={currentCollateral} />}
        </RowWithdrawTotal>
      </RowWithdrawTotalWrapper>
    </WithdrawModal>
  )
}
