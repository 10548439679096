import { Endpoint, schema } from '@data-client/rest'
import { Contracts } from '@fluencelabs/deal-ts-clients'
import { ContractMethodArgs } from '@fluencelabs/deal-ts-clients/dist/typechain-types/common'
import { BytesLike, JsonRpcSigner } from 'ethers'

import { getCollateral } from '../utils/getCollateral'
import { renderJsonString } from '../utils/renderJsonString'

import { DEPLOYMENT } from '../constants/config'

import { CommitmentLifecycleStatus } from './CapacityCommitmentFunded'
import CapacityCommitmentGQL from './CapacityCommitmentGQL'
import CCCount from './CCStats'

export const stakeCollateralCapacityCommitment = new Endpoint(
  async ({
    capacityCommitmentId,
    signer,
  }: {
    capacityCommitmentId: string
    signer: JsonRpcSigner
  }) => {
    const contracts = new Contracts(signer, DEPLOYMENT)
    const commitments = [capacityCommitmentId]

    const commitmentsWithCollateral = await Promise.all(
      commitments.map(async (commitment) => {
        return await getCollateral(contracts, commitment)
      }),
    )

    const totalCollateral = commitmentsWithCollateral.reduce(
      (acc, collateral) => {
        return acc + collateral
      },
      0n,
    )

    const args: ContractMethodArgs<[commitmentIds: BytesLike[]], 'payable'> = [
      [capacityCommitmentId],
      {
        value: totalCollateral,
        maxPriorityFeePerGas: 0,
      },
    ]

    const transaction = await contracts.diamond.depositCollateral(...args)
    await transaction.wait(1)

    return {
      capacityCommitment: {
        id: capacityCommitmentId,
        delegator: {
          id: signer.address,
        },
        totalCollateral,
        status: CommitmentLifecycleStatus.WaitStart,
      },
      transaction: {},
      count: {
        id: 'total',
      },
    }
  },
  {
    name: 'stakeCollateralCapacityCommitment',
    schema: {
      key: (params: unknown) =>
        `stakeCollateralCapacityCommitment ${renderJsonString(params)}`,
      capacityCommitment: CapacityCommitmentGQL,
      transaction: new schema.Object({}),
      count: new schema.Invalidate(CCCount),
    },
  },
)
