import { formatDistanceToNowStrict } from 'date-fns'

import { useConstants } from '../../hooks/useConstants'
import { useExpectedAPR } from '../../hooks/useExpectedAPR'
import { formatPercent } from '../../utils/formatPercent'
import { getShortAddress } from '../../utils/getShortAddress'

import { VITE_EXPLORER_URL } from '../../constants/config'
import CapacityCommitmentGQL, {
  CommitmentLifecycleStatus,
} from '../../store/CapacityCommitmentGQL'
import { A } from '../A'
import { CapacityStatus } from '../CapacityStatus'
import { ProviderIcon } from '../ProviderIcon'
import { Space } from '../Space'
import { Cell, CellProps, Row } from '../Table'
import { ShrinkText, Text, UppercaseText } from '../Text'
import TokenBalance from '../TokenBalance'

import { Caret } from './Caret'

export const ExplorerCell = ({
  capacityCommitment,
  left = 25,
  right = 25,
  link,
  ...rest
}: {
  capacityCommitment: CapacityCommitmentGQL
  left?: number
  right?: number
  link?: boolean
} & CellProps) => {
  const text = getShortAddress(capacityCommitment.id, left, right)

  return (
    <Cell {...rest}>
      {link ? (
        <A href={`${VITE_EXPLORER_URL}/capacity/${capacityCommitment.id}`}>
          {text}
        </A>
      ) : (
        <Text size={14}>{text}</Text>
      )}
    </Cell>
  )
}

export const ProviderCell = ({
  address,
  name,
  link,
  ...rest
}: {
  address: string
  name: string
  link?: boolean
} & CellProps) => {
  return (
    <Cell {...rest}>
      {link ? (
        <>
          <A href={`${VITE_EXPLORER_URL}/provider/${address}`}>
            <ProviderIcon address={address} />
          </A>
          <Space width="8px" />
          <A href={`${VITE_EXPLORER_URL}/provider/${address}`}>
            <ShrinkText size={12} color="blue">
              <Text size={14}>{name}</Text>
            </ShrinkText>
          </A>
        </>
      ) : (
        <>
          <ProviderIcon address={address} />
          <Space width="8px" />
          <ShrinkText size={12} color="blue">
            <Text size={14}>{name}</Text>
          </ShrinkText>
        </>
      )}
    </Cell>
  )
}

const ExpectedAPRCell = ({
  capacityCommitment,
  ...rest
}: {
  capacityCommitment: CapacityCommitmentGQL
} & CellProps) => {
  const value = useExpectedAPR(capacityCommitment)

  return (
    <Cell {...rest}>
      <Text size={12}>{value ? formatPercent(value) : '-'}</Text>
    </Cell>
  )
}

export function CapacityCommitmentLine({
  columns,
  capacityCommitment,
  staking,
  onExpand,
}: {
  onExpand: () => void
  staking?: boolean
  columns: string[][][]
  capacityCommitment: CapacityCommitmentGQL
}) {
  const constants = useConstants()

  if (!constants) return null

  const stakingDuration =
    capacityCommitment.duration * constants.coreEpochDuration * 1000

  return (
    <Row template={columns}>
      <ExplorerCell capacityCommitment={capacityCommitment} link />
      {staking ? (
        <>
          <ProviderCell
            address={capacityCommitment.provider.id}
            name={capacityCommitment.provider.name}
            link
          />
          <Cell>
            <Text size={12}>
              {formatDistanceToNowStrict(Date.now() + stakingDuration)}
            </Text>
          </Cell>
          <Cell>
            <Text size={12}>
              {formatPercent(capacityCommitment.stakingReward)}
            </Text>
          </Cell>
          <ExpectedAPRCell capacityCommitment={capacityCommitment} />
          <Cell gap="6px" justifyContent="right">
            <TokenBalance
              size="small"
              balance={capacityCommitment.requiredCollateral}
            />
          </Cell>
        </>
      ) : (
        <>
          <ProviderCell
            address={capacityCommitment.provider.id}
            name={capacityCommitment.provider.name}
            link
          />
          <Cell>
            <UppercaseText size={12} onlyFirst>
              {capacityCommitment.status === CommitmentLifecycleStatus.Failed
                ? '-'
                : capacityCommitment.endEpoch &&
                  formatDistanceToNowStrict(
                    new Date(
                      constants.calculateTimestamp(
                        capacityCommitment.endEpoch,
                      ) * 1000,
                    ),
                    {
                      addSuffix: true,
                    },
                  )}
            </UppercaseText>
          </Cell>
          <Cell>
            <Text size={12}>
              {formatPercent(capacityCommitment.stakingReward)}
            </Text>
          </Cell>
          <Cell>
            <CapacityStatus capacity={capacityCommitment} />
          </Cell>
        </>
      )}
      <Cell>
        <Caret onToggle={onExpand} />
      </Cell>
    </Row>
  )
}
