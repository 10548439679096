import { getShortAddress } from '../../utils/getShortAddress'

import { CapacityCommitmentRewards } from '../../store/CapacityCommitmentRewards'
import { Copyable } from '../Copyable'
import { Cell, Row, RowBlock } from '../Table'
import { Text } from '../Text'
import TokenBalance from '../TokenBalance'

import { RowContainer, RowHeaderBase } from './styled'

const ROW_TEMPLATE = [
  [['minmax(250px, 1fr)', 'minmax(55px, 1fr)'], []],
  [['minmax(250px, 1fr)', 'minmax(55px, 1fr)'], []],
]

export function CommitmentsWithRewards({
  capacityCommitments,
}: {
  capacityCommitments: CapacityCommitmentRewards[]
}) {
  return (
    <RowContainer>
      {capacityCommitments.map((capacityCommitment) => (
        <RowBlock key={capacityCommitment.id}>
          <RowHeaderBase>
            <Row template={ROW_TEMPLATE}>
              <Cell>
                <Copyable value={capacityCommitment.id}>
                  <Text size={12} color="blue">
                    {getShortAddress(capacityCommitment.id, 25)}
                  </Text>
                </Copyable>
              </Cell>
              <Cell justifyContent="right">
                <TokenBalance balance={capacityCommitment.availableToClaim} />
              </Cell>
            </Row>
          </RowHeaderBase>
        </RowBlock>
      ))}
    </RowContainer>
  )
}
