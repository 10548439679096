import { useCallback } from 'react'

import { CaretIconStyled } from './styled'

export function Caret({ onToggle }: { onToggle: () => void }) {
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      onToggle()
    },
    [onToggle],
  )

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.code !== 'Enter') return
      e.preventDefault()
      onToggle()
    },
    [onToggle],
  )

  return (
    <CaretIconStyled tabIndex={0} onClick={onClick} onKeyDown={onKeyDown} />
  )
}
