import Big from 'big.js'

import { fixedPrecisionValueToBig } from '../utils/fixedPrecisionValueToBig'

import { useConstants } from './useConstants'

export function useCurrentCollateral({
  totalCollateral,
  collateralPerUnit,
  collateralWithdrawn,
  totalFailCount = 0,
}: {
  totalCollateral: Big
  collateralPerUnit: Big
  collateralWithdrawn: boolean
  totalFailCount?: number
}) {
  const constants = useConstants()

  if (collateralWithdrawn) return Big(0)

  if (!constants) return null

  const rate = fixedPrecisionValueToBig(constants.slashingRate)
  const slashedCollateral = collateralPerUnit.times(totalFailCount).times(rate)

  if (slashedCollateral.gt(totalCollateral)) return Big(0)

  return totalCollateral.minus(slashedCollateral)
}
