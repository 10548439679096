import { Multicall3 } from '@fluencelabs/deal-ts-clients'
import { Interface, Result } from 'ethers'

import { createContracts } from '../utils/createDealClient'

interface RewardsResult {
  ccRewards: bigint
  dealStakerRewards: bigint
}

function serializeTxToRewards(result: Result | null): RewardsResult | null {
  if (!result || result.length === 0) {
    return null
  }

  const firstResult = result[0]

  if (!Array.isArray(firstResult) || firstResult.length < 2) {
    throw new Error('Unexpected result structure in serializeTxToRewards.')
  }

  const [ccRewards, dealStakerRewards] = firstResult

  return {
    ccRewards,
    dealStakerRewards,
  }
}

interface Multicall3ContractCall {
  target: string
  allowFailure: boolean
  callData: string
}

interface Aggregate3Response {
  success: boolean
  returnData: string
}

type TxResultsConverter = (result: Result | null, ...opt: unknown[]) => unknown

async function callBatch(
  multicall3Contract: Multicall3,
  callsEncoded: Multicall3ContractCall[],
  callResultsInterfaces: Interface[],
  contractMethods: string[],
  txResultsConverters: TxResultsConverter[],
) {
  if (
    (callsEncoded.length +
      callResultsInterfaces.length +
      contractMethods.length +
      txResultsConverters.length) %
      4 !=
    0
  ) {
    throw new Error(
      'Assertion: callsEncoded, callResultsInterfaces, contractMethods, txResultsConverters should have the same length.',
    )
  }

  const multicallContractCallResults: Aggregate3Response[] =
    await multicall3Contract.aggregate3.staticCall(callsEncoded)

  const decodedResults: unknown[] = []
  for (let i = 0; i < multicallContractCallResults.length; i++) {
    const txResultsConverter = txResultsConverters[i]
    const contractMethod = contractMethods[i]
    const callResultsInterface = callResultsInterfaces[i]
    if (
      txResultsConverter == undefined ||
      contractMethod == undefined ||
      callResultsInterface == undefined
    ) {
      throw new Error(
        'Assertion: txResultsConverter or contractMethod or callResultsInterface is undefined.',
      )
    }

    const rawResult = multicallContractCallResults[i]
    if (!rawResult?.success) {
      decodedResults.push(txResultsConverter(null))
      continue
    }

    const rawReturnData = rawResult.returnData

    const decoded = callResultsInterface.decodeFunctionResult(
      contractMethod,
      rawReturnData,
    )
    decodedResults.push(txResultsConverter(decoded))
  }
  return decodedResults
}

export async function getCapacityCommitmentRewardsBatch<T>(
  ccs: ({ id: string } & T)[],
) {
  const contracts = createContracts()

  const diamondInterface = contracts.diamond.interface
  const diamondAddress = await contracts.diamond.getAddress()
  function methodCalls(
    ccs: { id: string }[],
    method: 'unlockedRewards' | 'totalRewards' | 'withdrawnRewards',
  ) {
    return ccs.map(({ id }) => {
      let callData = ''
      if (method === 'unlockedRewards') {
        callData = diamondInterface.encodeFunctionData(method, [
          id.toLowerCase(),
        ])
      } else if (method === 'totalRewards') {
        callData = diamondInterface.encodeFunctionData(method, [
          id.toLowerCase(),
        ])
      } else if (method === 'withdrawnRewards') {
        callData = diamondInterface.encodeFunctionData(method, [
          id.toLowerCase(),
        ])
      }
      return { target: diamondAddress, allowFailure: true, callData }
    })
  }

  const batchStep = 30
  const details = []

  for (let batchId = 0; batchId < ccs.length; batchId += batchStep) {
    const batchCapacityCommitmentIds = ccs.slice(batchId, batchId + batchStep)
    const count = batchCapacityCommitmentIds.length
    const calls = [
      ...methodCalls(batchCapacityCommitmentIds, 'unlockedRewards'),
      ...methodCalls(batchCapacityCommitmentIds, 'totalRewards'),
      ...methodCalls(batchCapacityCommitmentIds, 'withdrawnRewards'),
    ]
    const interfaces = new Array(calls.length).fill(diamondInterface)

    const methods = [
      ...new Array(count).fill('unlockedRewards'),
      ...new Array(count).fill('totalRewards'),
      ...new Array(count).fill('withdrawnRewards'),
    ]
    const converters = [
      ...new Array(count).fill(serializeTxToRewards),
      ...new Array(count).fill(serializeTxToRewards),
      ...new Array(count).fill(serializeTxToRewards),
    ]

    const results = await callBatch(
      contracts.multicall3,
      calls,
      interfaces,
      methods,
      converters,
    )

    if (results.length != calls.length) {
      throw new Error(
        '[getCapacityCommitmentDetails] Assertion: results.length != calls.length',
      )
    }

    const unlocked = results.slice(0, batchCapacityCommitmentIds.length)
    const totals = results.slice(
      batchCapacityCommitmentIds.length,
      batchCapacityCommitmentIds.length * 2,
    )
    const withdrawn = results.slice(
      batchCapacityCommitmentIds.length * 2,
      batchCapacityCommitmentIds.length * 3,
    )

    details.push(
      ...batchCapacityCommitmentIds.map((cc, index) => ({
        ...cc,
        unlockedRewards: unlocked[index],
        totalRewards: totals[index],
        withdrawnRewards: withdrawn[index],
      })),
    )
  }

  if (details.length != ccs.length) {
    throw new Error(
      '[getCapacityCommitmentDetails] Assertion: details.length != capacityCommitmentIds.length',
    )
  }

  return details
}
