import React, { ReactElement, useEffect, useState } from 'react'
import { useSuspense } from '@data-client/react'
import { CapacityCommitmentsStakerFilters } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerFilters'
import * as Accordion from '@radix-ui/react-accordion'

import { MessageBlock, MessageContainer } from '../../components/Message'
import { Space } from '../../components/Space'
import {
  TableBody,
  TableColumnTitle,
  TableColumnTitleWithSort,
  TableHeader,
  TableResponsive,
  TableResponsiveWrapper,
} from '../../components/Table'
import { TableFooter } from '../../components/Table/TableFooter'
import { Text } from '../../components/Text'
import { usePagination } from '../../hooks'
import { usePrevious } from '../../hooks/usePrevious'

import {
  CapacityCommitment_OrderBy,
  OrderDirection,
} from '../../generated/graphql'
import CapacityCommitmentGQL from '../../store/CapacityCommitmentGQL'
import {
  CapacityCommitmentGQLQuery,
  setupVariables,
} from '../../store/CapacityCommitmentGQLQuery'

import { ROW_TEMPLATE } from './styled'

type CapacityCommitmentSort = `${CapacityCommitment_OrderBy}:${OrderDirection}`

interface CapacityCommitmentTableProps {
  columns: string[][][]
  filters: CapacityCommitmentsStakerFilters
  stakerId?: string
  renderRow: (params: {
    capacityCommitment: CapacityCommitmentGQL
    onExpand: (id: string) => void
    columns: string[][][]
  }) => ReactElement
}

const CAPACITY_COMMITMENTS_PER_PAGE = 5

export const CapacityCommitmentTable: React.FC<
  CapacityCommitmentTableProps
> = ({ filters, renderRow, columns, stakerId }) => {
  const [ccPerPage, setCCPerPage] = useState(CAPACITY_COMMITMENTS_PER_PAGE)
  const [order, setOrder] = useState<CapacityCommitmentSort>(
    'rewardRatePerCollateral:desc',
  )
  const [orderBy, orderType] = order.split(':') as [
    CapacityCommitment_OrderBy,
    OrderDirection,
  ]

  const { page, selectPage, limit, offset, getTotalPages } =
    usePagination(ccPerPage)

  const prevStakerId = usePrevious(stakerId)
  const prevFilters = usePrevious(filters)
  const prevCCPerPage = usePrevious(ccPerPage)
  useEffect(() => {
    if (
      prevStakerId !== stakerId ||
      prevFilters !== filters ||
      prevCCPerPage !== ccPerPage
    )
      selectPage(1)
  }, [
    stakerId,
    prevStakerId,
    selectPage,
    prevFilters,
    filters,
    prevCCPerPage,
    ccPerPage,
  ])

  const [isAccordionOpen, setIsAccordionOpen] = useState<string[]>([])

  const { capacityCommitments } = useSuspense(
    CapacityCommitmentGQLQuery,
    setupVariables({
      searchBy: filters?.searchBy,
      stakerId,
      orderBy,
      orderType,
    }),
  )

  const allCapacityCommitments = capacityCommitments
  const hasNextPage =
    allCapacityCommitments && allCapacityCommitments.length > limit
  const pageCapacityCommitments =
    allCapacityCommitments &&
    allCapacityCommitments.slice(offset, offset + limit)

  const onExpand = (id: string) => {
    if (isAccordionOpen.includes(id)) {
      return setIsAccordionOpen((arr) => arr.filter((v) => v !== id))
    }

    setIsAccordionOpen([...isAccordionOpen, id])
  }

  const handleSort = (
    key: CapacityCommitment_OrderBy,
    order: OrderDirection,
  ) => {
    setOrder(`${key}:${order}`)
  }
  return (
    <>
      <TableResponsiveWrapper>
        <TableResponsive>
          {capacityCommitments.length !== 0 && (
            <TableHeader template={ROW_TEMPLATE}>
              <TableColumnTitle>Capacity Commitment Id</TableColumnTitle>
              <TableColumnTitle>Provider Name</TableColumnTitle>
              <TableColumnTitleWithSort
                order={orderType}
                field={CapacityCommitment_OrderBy.Duration}
                isActive={orderBy === CapacityCommitment_OrderBy.Duration}
                onSort={handleSort}
                hint="How long the staked funds will be locked"
              >
                Staking Duration
              </TableColumnTitleWithSort>
              <TableColumnTitleWithSort
                order={orderType}
                field={CapacityCommitment_OrderBy.RewardDelegatorRate}
                isActive={
                  orderBy === CapacityCommitment_OrderBy.RewardDelegatorRate
                }
                onSort={handleSort}
                hint="The share of rewards the staker earns for Capacity Commitment, defined by provider"
              >
                Staking Reward
              </TableColumnTitleWithSort>
              <TableColumnTitleWithSort
                order={orderType}
                field={CapacityCommitment_OrderBy.RewardRatePerCollateral}
                isActive={
                  orderBy === CapacityCommitment_OrderBy.RewardRatePerCollateral
                }
                onSort={handleSort}
                hint="Estimated annual percentage return based on target reward per CU per epoch"
              >
                Expected Apr
              </TableColumnTitleWithSort>
              <TableColumnTitleWithSort
                order={orderType}
                field={CapacityCommitment_OrderBy.RequiredCollateral}
                isActive={
                  orderBy === CapacityCommitment_OrderBy.RequiredCollateral
                }
                onSort={handleSort}
                justifyContent="end"
                hint="Amount of FLT tokens needed to activate this commitment"
              >
                Required Collateral
              </TableColumnTitleWithSort>
            </TableHeader>
          )}
          <Accordion.Root type="multiple" value={isAccordionOpen}>
            <TableBody
              skeletonCount={CAPACITY_COMMITMENTS_PER_PAGE}
              skeletonHeight={40}
              isEmpty={capacityCommitments.length === 0}
              noDataView={
                Object.keys(filters).length === 0 ? (
                  <MessageContainer>
                    <MessageBlock>
                      <Text color="black900" size={18}>
                        All Capacity Commitments are currently staked
                        <br /> We’re working to onboard more. Stay tuned
                      </Text>
                    </MessageBlock>
                  </MessageContainer>
                ) : (
                  <MessageContainer>
                    <MessageBlock>
                      <Text color="black900" size={14}>
                        Not found Capacity Commitment
                      </Text>
                    </MessageBlock>
                  </MessageContainer>
                )
              }
            >
              {pageCapacityCommitments?.map((capacityCommitment) =>
                renderRow({ capacityCommitment, onExpand, columns }),
              )}
            </TableBody>
          </Accordion.Root>
        </TableResponsive>
      </TableResponsiveWrapper>
      <Space height="32px" />
      {capacityCommitments && (
        <TableFooter
          countItems={ccPerPage}
          onSetCountItems={setCCPerPage}
          pages={getTotalPages(allCapacityCommitments?.length)}
          page={page}
          hasNextPage={hasNextPage}
          onSelect={selectPage}
        />
      )}
    </>
  )
}
