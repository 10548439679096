import { useContext } from 'react'
import { useController, useQuery } from '@data-client/react'
import * as Sentry from '@sentry/react'
import { JsonRpcSigner } from 'ethers'

import { getShortAddress } from '../../utils/getShortAddress'

import { CapacityCommitmentFunded } from '../../store/CapacityCommitmentFunded'
import { CommitmentLifecycleStatus } from '../../store/CapacityCommitmentGQL'
import { withdrawCollateralCapacityCommitment } from '../../store/withdrawCollateralCapacityCommitment'
import { TransactionWaitingModal } from '../Modals/TransactionWaitingModal'
import { SignerContext } from '../SignerProvider'
import { WithdrawCollateralModalContext } from '../WithdrawCollateralModalProvider'
import { WithdrawStatus } from '../WithdrawModalProvider'

import { WithdrawFromCompletedCC } from './WithdrawFromCompletedCC'
import { WithdrawFromFailedCC } from './WithdrawFromFailedCC'
import WithdrawTransactionFailedModal from './WithdrawTransactionFailedModal'
import WithdrawTransactionSuccessModal from './WithdrawTransactionSuccessModal'

function WithdrawModalContainer({
  capacityCommitment,
  updateStatus,
  signer,
  onClose,
}: {
  onClose: () => void
  signer: JsonRpcSigner
  capacityCommitment: CapacityCommitmentFunded
  updateStatus: (status: WithdrawStatus) => void
}) {
  const ctrl = useController()
  const info = useQuery(CapacityCommitmentFunded, {
    id: capacityCommitment.id,
  })

  async function onWithdraw() {
    try {
      updateStatus('pending')
      await ctrl.fetch(withdrawCollateralCapacityCommitment, {
        capacityCommitmentId: capacityCommitment.id,
        signer,
      })
      updateStatus('success')
    } catch (e) {
      Sentry.captureException(e)
      console.error(e)
      updateStatus('failed')
    }
  }

  if (!info) return null

  if (info.status === CommitmentLifecycleStatus.Failed) {
    return (
      <WithdrawFromFailedCC
        capacityCommitment={info}
        onClose={onClose}
        onWithdraw={onWithdraw}
      />
    )
  }

  return (
    <WithdrawFromCompletedCC
      capacityCommitment={info}
      onClose={onClose}
      onWithdraw={onWithdraw}
    />
  )
}

export const WithdrawCollateralModals = () => {
  const { signer } = useContext(SignerContext)
  const { data, status, setStatus, setCurrentId } = useContext(
    WithdrawCollateralModalContext,
  )

  if (!data?.capacityCommitment || !signer) return null

  function onClose() {
    setCurrentId('')
  }

  const { capacityCommitment } = data

  if (status === 'waiting') {
    return (
      <WithdrawModalContainer
        signer={signer}
        onClose={onClose}
        capacityCommitment={capacityCommitment}
        updateStatus={(status: WithdrawStatus) =>
          data && setStatus(data.id, status)
        }
      />
    )
  }

  if (status === 'pending') {
    return (
      <TransactionWaitingModal
        onClose={onClose}
        isVisible
        description={`Capacity commitment ID: ${getShortAddress(capacityCommitment.id, 12)}`}
      />
    )
  }

  if (status === 'success') {
    return (
      <WithdrawTransactionSuccessModal
        title={`You have successfully withdrawn collateral for ${getShortAddress(capacityCommitment.id, 12)}`}
        onClose={onClose}
      />
    )
  }

  if (status === 'failed') {
    return <WithdrawTransactionFailedModal onClose={onClose} />
  }

  return null
}
